import {
  isObject,
  isArray,
  isString,
  createSorter,
  repeat,
  tryit,
  clone
} from "@bsgp/lib-core";
import { ft } from "@bsgp/form-and-table";
import { splitSpecialKeyIfExists } from "../mods";
const commonOptions = ["properties-width", "properties-enabled"];
const optsPerTypes = {
  forms: ["table"],
  fields: [],
  tables: [
    "items",
    "closeDialogOnSelect",
    "mode",
    "usePagination",
    "isTreeTable"
  ],
  columns: ["properties-styleClass"],
  dialogs: [
    "fullSize",
    "beginButtonText",
    "endButtonText",
    "hideBeginButton",
    "disableEsc"
  ],
  buttons: [],
  nodeeditors: ["onSelect", "onNodeDoubleClick", "enabled"]
};
const optsPerComps = {
  Input: [
    "properties-valueHelpOnly",
    "properties-editable",
    "properties-textAlign",
    "onChange",
    "onSubmit",
    "valueHelpV2-dialogId",
    "valueHelpV2-onConfirm",
    "valueHelpV2-onRequest"
  ],
  TextArea: [
    "properties-editable",
    "properties-rows",
    "properties-growing",
    "proerties-growingMaxLines",
    "properties-height",
    "onChange"
  ],
  Button: [
    "properties-type", //* button style type
    "properties-text",
    "properties-icon",
    "onPress"
  ],
  Select: ["list", "onChange"],
  CheckBox: ["text", "onChange"],
  ComboBox: "Select",
  Date: ["format", "displayFormat", "onChange"],
  DateRange: "Date",
  Radio: "Select",
  Number: ["unit"],
  Quantity: "Number",
  Amount: "Number",
  ScannerButton: ["onScan", "onError"],
  Link: ["onPress", "text"],
  Uploader: ["onChange"],
  Status: ["state", "inverted"]
};

export const getMixedOptions = (appliedOptions, { type, compType }) => {
  const availableOpts4Comp = [];
  if (compType) {
    const optsPer = optsPerComps[compType];
    if (isString(optsPer)) {
      (optsPerComps[optsPer] || []).forEach(each => {
        availableOpts4Comp.push(each);
      });
    } else {
      (optsPer || []).forEach(each => {
        availableOpts4Comp.push(each);
      });
    }
  }

  const availableOpts4Type = [];
  if (type) {
    const optsPer = optsPerTypes[type];
    if (isString(optsPer)) {
      (optsPerTypes[optsPer] || []).forEach(each => {
        availableOpts4Type.push(each);
      });
    } else {
      (optsPer || []).forEach(each => {
        availableOpts4Type.push(each);
      });
    }
  }

  const mixedOptions = commonOptions
    .concat(availableOpts4Comp)
    .concat(availableOpts4Type);

  const manualOptions = appliedOptions.reduce((acc, each) => {
    if (!mixedOptions.includes(each.key)) {
      acc = acc.concat(each.key);
    }
    return acc;
  }, []);

  return mixedOptions.concat(manualOptions);
};

const getOptionsWithCombinedKey = (obj, propertyName) => {
  if (obj[propertyName]) {
    return Object.entries(obj[propertyName]).map(([propKey, propValue]) => {
      return {
        key: `${propertyName}-${propKey}`,
        ...propValue
      };
    });
  }
  return [];
};

export const getOptionsOnDialog = dataOnDialog => {
  if (dataOnDialog === undefined) {
    return;
  }

  // 첫 레벨의 속성들을 구분하여 취합;
  const currentDialogOptions = Object.entries(dataOnDialog)
    .filter(([, value]) => {
      if (isObject(value)) {
        return (
          (Object.hasOwn(value, "type") && Object.hasOwn(value, "value")) ||
          (Object.hasOwn(value, "type") && Object.hasOwn(value, "seq"))
        );
      }
      return false;
    })
    .map(([key, value]) => {
      return { key, ...value };
    });

  currentDialogOptions.push(
    ...getOptionsWithCombinedKey(dataOnDialog, "properties")
  );
  currentDialogOptions.push(
    ...getOptionsWithCombinedKey(dataOnDialog, "valueHelpV2")
  );

  currentDialogOptions.sort(createSorter(["seq"]));

  return currentDialogOptions;
};

export const metaKeyStucture = {
  forms: {
    containers: {
      fields: {
        components: {}
      }
    }
  },
  tables: {
    columns: {
      components: {}
    },
    toolbars: {
      buttons: {}
    }
  },
  headers: {
    buttons: {}
  },
  codeeditors: {
    components: {}
  },
  nodeeditors: {
    components: {}
  },
  dialogs: {
    forms: {
      containers: {
        fields: {
          components: {}
        }
      }
    },
    tables: {
      columns: {
        components: {}
      },
      toolbars: {
        buttons: {}
      }
    },
    codeeditors: {
      components: {}
    },
    nodeeditors: {
      components: {}
    }
  }
};

export const metaIndexMap = {
  forms: "fIdx",
  containers: "cIdx",
  fields: "fiIdx",
  components: "compIdx",
  tables: "tIdx",
  columns: "coIdx",
  toolbars: "toIdx",
  headers: "hIdx",
  buttons: "bIdx",
  dialogs: "dIdx",
  codeeditors: "codeIdx",
  nodeeditors: "nodeIdx"
};

const getSubKeys = ([supKey, supObj]) => {
  return Object.entries(supObj[supKey]).map(([key]) => [key, supObj[supKey]]);
};

const addItemsBySubObj = (
  builder,
  [key_s, parentStructure],
  { rootIdx, rootKey_s, parentObj, parentItem, depth }
) => {
  const inDepth = depth || 0;
  const subKeyEntries = getSubKeys([key_s, parentStructure]);

  if (subKeyEntries.length === 0) {
    return [];
  }

  const innerItems = [];

  subKeyEntries.forEach(([subKey_s, subStructure]) => {
    const subKey = subKey_s.replace(/s{1}$/, "");
    const itemKeys = [];
    const itemIndecies = [];

    if (!parentItem) {
      itemKeys.push(rootKey_s);
      itemIndecies.push(rootIdx);
      builder.addToolbarButton(`add${subKey}Btn_${rootIdx}`, {
        icon: "sap-icon://add",
        text: `${subKey} 추가`,
        confirmMessage: "Are you sure to add?",
        properties: {
          enabled: key_s === "dialogs" && parentObj.metaId ? false : true
        },
        onPress: fn =>
          fn.addExtraObject({
            keys: [...itemKeys, subKey_s],
            indecies: itemIndecies,
            subKey
          })
      });
    } else {
      itemKeys.push(...parentItem.keys);
      itemIndecies.push(...parentItem.indecies);
    }

    if (parentItem) {
      parentItem.sub2Key_list.add(subKey);
      parentItem.sub2Key_s_list.add(subKey_s);
      // parentItem.keys.push(subKey_s);
    }
    if (parentObj[subKey_s] === undefined || parentObj[subKey_s].length === 0) {
      return [];
    }

    parentObj[subKey_s].forEach((subObj, subIndex) => {
      let text;
      if (subKey === "field") {
        text = subObj.label;
      } else if (Object.hasOwn(subObj, "title") && !isObject(subObj.title)) {
        text = subObj.title;
      } else if (Object.hasOwn(subObj, "text")) {
        if (Object.hasOwn(subObj.text, "value")) {
          text = subObj.text.value;
        } else {
          text = subObj.text;
        }
      } else {
        text = tryit(() => subObj.properties.text.value, "");
      }

      if (!text) {
        if (Object.hasOwn(subObj, "value")) {
          if (subObj.value.type === "String") {
            text = subObj.value.value;
          }
        }
      }

      let type;
      if (subKey === "button") {
        type = "";
      } else {
        if (Object.hasOwn(subObj, "type")) {
          if (Object.hasOwn(subObj.type, "value")) {
            type = subObj.type.value;
          } else {
            type = subObj.type;
          }
        }
      }

      const item = {
        ...parentItem,
        keys: [...itemKeys, subKey_s],
        indecies: [...itemIndecies, subIndex],
        subKey,
        subKey_s,
        sub2Key_list: new Set(),
        sub2Key_s_list: new Set(),
        command: Array(inDepth + 1).join("  ") + `Add ${subKey}`,
        key: subObj.key,
        ignore: subObj.ignore,
        bound: !!subObj.binding,
        type,
        props: "Edit",
        text,
        [metaIndexMap[rootKey_s]]: rootIdx,
        [metaIndexMap[subKey_s]]: subIndex
      };

      const innerItems2 = addItemsBySubObj(builder, [subKey_s, subStructure], {
        rootIdx,
        rootKey_s,
        parentObj: subObj,
        parentItem: item,
        depth: inDepth + 1
      });

      item.sub2Key_list = Array.from(item.sub2Key_list);
      item.sub2Key_s_list = Array.from(item.sub2Key_s_list);
      item.sub2Key_list.forEach((sub2Key, sub2Idx) => {
        item[`sub2Key_${sub2Idx}`] = sub2Key;
        item[`sub2Key_s_${sub2Idx}`] = item.sub2Key_s_list[sub2Idx];
        item[`addSubBtn_${sub2Idx}`] = `Add ${sub2Key}`;
      });

      innerItems.push(item);

      innerItems.push(...innerItems2);
    });
  });

  return innerItems;
};

export const addMetaTable = (builder, rootObj, rootIdx, rootKey_s) => {
  const rootKey = rootKey_s.replace(/s{1}$/, "");

  builder
    .addTable(`${rootKey}_${rootIdx}`, {
      icon: rootObj.ignore === true ? "sap-icon://cancel" : "",
      title: [rootKey, " "]
        .join("")
        .concat(rootObj.title || [" [", rootObj.key, "]"].join(""))
    })
    .addToolbarButton("edit", {
      icon: "sap-icon://edit",
      text: `${rootKey} 편집`,
      onPress: fn =>
        fn.openEditDialog2({
          rootKey_s,
          indexValue: rootIdx,
          indexKey: metaIndexMap[rootKey_s]
        })
    });

  const listItems = addItemsBySubObj(builder, [rootKey_s, metaKeyStucture], {
    rootIdx,
    rootKey_s,
    parentObj: rootObj
  });

  if (["forms", "tables"].includes(rootKey_s)) {
    builder.addToolbarAction("moveToDialog", {
      properties: {
        icon: "sap-icon://indent"
      },
      text: `${rootKey} - Dialog으로 이동`,
      onPress: fn =>
        fn.toggleMoveDialog(true, {
          rootKey_s,
          indexValue: rootIdx,
          indexKey: metaIndexMap[rootKey_s]
        })
    });

    builder.addToolbarAction("exportMeta", {
      properties: {
        icon: "sap-icon://forward"
      },
      text: `${rootKey} - Export`,
      onPress: fn =>
        fn.toggleExportDialog(true, {
          rootKey_s,
          indexValue: rootIdx,
          indexKey: metaIndexMap[rootKey_s]
        })
    });
  }

  builder
    .addColumn("command", {
      width: "10rem",
      text: "Command"
    })
    .addColumn("key", {
      text: "Key"
    })
    .addColumn("ignore", {
      text: "ignore",
      width: "5rem",
      component: ft.CheckBox({
        properties: {
          enabled: false
        }
      })
    })
    .addColumn("bound", {
      text: "Bound",
      width: "5rem",
      component: ft.CheckBox({
        properties: {
          enabled: false
        }
      })
    })
    .addColumn("type", {
      width: "5rem",
      text: "Type"
    })
    .addColumn("text", {
      text: "Label/Text"
    })
    .addColumn("props", {
      icon: "sap-icon://edit",
      text: "Properties",
      width: "5rem",
      component: ft.Button({
        onPress: fn => fn.openSubDialog({ rootKey_s, rootIdx })
      })
    });

  const countOfSub2Key = listItems.reduce((acc, cur) => {
    if (acc < cur.sub2Key_list.length) {
      return cur.sub2Key_list.length;
    }
    return acc;
  }, 0);

  repeat({ maxTimes: countOfSub2Key }, ({ curIndex }) => {
    builder.addColumn(`addSubBtn_${curIndex}`, {
      text: "",
      component: ft.Button({
        confirmMessage: "Are you sure to add?",
        properties: {
          icon: "sap-icon://add",
          visible: ["{=", "!!${sub2Key_", curIndex, "}", "}"].join("")
        },
        onPress: fn => fn.addExtraObject({ sub2Index: curIndex })
      })
    });
  });

  builder.addItems(listItems);

  return builder;
};

export const getTargetArray = (state, inKeys, indecies) => {
  let innerState = state;
  let firstIsLast = false;
  let lastKey;
  const { target } = state.extra.dialog;
  const keys = inKeys || target.keys;
  let parentObj;

  keys.forEach((key, index) => {
    const isLast = keys.length - 1 === index;

    if (isLast) {
      parentObj = innerState;

      innerState = innerState[key];

      lastKey = key;

      if (index === 0) {
        firstIsLast = true;
      } else {
        // pass
      }
    } else {
      innerState =
        innerState[key][indecies ? indecies[index] : target[metaIndexMap[key]]];
    }
  });

  return { parentObj, metaArray: innerState, firstIsLast, lastKey };
};
export const getTargetObj = (state, inKeys, indecies) => {
  const { target } = state.extra.dialog;
  const keys = inKeys || target.keys;
  const result = getTargetArray(state, keys, indecies);
  const lastIndex = target[metaIndexMap[result.lastKey]];

  return {
    ...result,
    metaObj: result.metaArray[lastIndex],
    lastIndex
  };
};

export const getParentObjects = (meta, keys) => {
  const inKeys = clone(keys);
  inKeys.pop(); // remove last key
  const parentKey = inKeys[inKeys.length - 1];
  const arr1 = inKeys.reduce(
    (acc, key) => {
      const newAcc = [];
      acc.forEach(eachAcc => {
        if (eachAcc[key] !== undefined) {
          newAcc.push(...eachAcc[key]);
        }
      });

      return newAcc;
    },
    [meta]
  );
  console.log("inKeys", clone(inKeys), arr1);

  inKeys.unshift("dialogs"); // add first key
  const arr2 = inKeys.reduce(
    (acc, key) => {
      const newAcc = [];
      acc.forEach(eachAcc => {
        if (eachAcc[key] !== undefined) {
          newAcc.push(...eachAcc[key]);
        }
      });

      return newAcc;
    },
    [meta]
  );
  console.log("inKeys", clone(inKeys), arr2);

  return {
    list: arr1.concat(arr2),
    parentKey: parentKey,
    parentType: parentKey.replace(/s{1}$/, "")
  };
};

export const replaceInvalidChar = key => {
  /*
  Stable ID (sId) must match with following regEx;
  /^([A-Za-z_][-A-Za-z0-9_.:]*)$/.test(vValue)
  link: https://openui5.hana.ondemand.com/api/sap.ui.core.ID
  link: https://github.com/SAP/openui5/blob/
  0d2d992ed578f88bd9f0f7d7a587a9c7cd986e6b/src/
  sap.ui.core/src/sap/ui/core/library.js#L1064
  */
  return key.replace(/[^-A-Za-z0-9_.:]/g, "_").replace(/^[^A-Za-z_]{1}/, "_");
};

export const getBindParams = value => {
  if (!isString(value)) {
    return [];
  }
  const regex = /\{{2}([^{}]{1,})\}{2}/g;
  const found = value.match(regex);
  return found || [];
};

export const getOptionTarget = (key, currentDialog, options = {}) => {
  const { replaceWith, deleteTarget } = options;
  let oldValue;
  if (replaceWith !== undefined) {
    oldValue = getOptionTarget(replaceWith, currentDialog, {
      deleteTarget: true
    });
  }

  if (splitSpecialKeyIfExists(key)) {
    const [propsKey, propsName] = splitSpecialKeyIfExists(key);

    if (currentDialog[propsKey] === undefined) {
      currentDialog[propsKey] = {};
    }
    if (currentDialog[propsKey][propsName] === undefined) {
      currentDialog[propsKey][propsName] = {};
    }

    if (replaceWith !== undefined) {
      currentDialog[propsKey][propsName] = oldValue;
    }

    if (deleteTarget === true) {
      const clonedTarget = clone(currentDialog[propsKey][propsName]);
      delete currentDialog[propsKey][propsName];
      return clonedTarget;
    }

    return currentDialog[propsKey][propsName];
  } else {
    if (currentDialog[key] === undefined) {
      currentDialog[key] = {};
    }

    if (replaceWith !== undefined) {
      currentDialog[key] = oldValue;
    }

    if (deleteTarget === true) {
      const clonedTarget = clone(currentDialog[key]);
      delete currentDialog[key];
      return clonedTarget;
    }

    return currentDialog[key];
  }
};

export const getCombinedKeyString = (
  keys,
  meta,
  { target, indecies, combineAllKeys }
) => {
  return keys
    .reduce(
      (acc, key, idx) => {
        if (indecies) {
          if (idx === indecies.length) {
            return acc;
          }
        }
        const targetIndex = indecies
          ? indecies[idx]
          : target[metaIndexMap[key]];
        acc.innerState = acc.innerState[key][targetIndex];
        if (
          combineAllKeys !== true &&
          ["containers", "fields", "dialogs", "columns"].includes(key)
        ) {
          // pass
        } else {
          acc.result.push(acc.innerState.key);
        }
        return acc;
      },
      { result: [], innerState: meta }
    )
    .result.map(key => replaceInvalidChar(key))
    .join(".");
};

export const getFunctionHeader = (currentDialog, options = {}) => {
  const { addBracket, addContextKeys } = options;
  const strParams = addContextKeys
    ? currentDialog.target.contextKeys.join(", ")
    : "";
  if (strParams === "") {
    return "";
  }

  return [
    (currentDialog.target.isAsync ? "async " : "").concat("("),
    addBracket ? "{" : "",
    strParams,
    addBracket ? "}" : "",
    ")"
  ]
    .filter(Boolean)
    .join("");
};

export const getDefaultPathValue = (
  optionKey,
  keys,
  meta,
  { target, indecies }
) => {
  if (optionKey === "value") {
    const prefix = `state.${keys.filter(key => key !== "dialogs")[0]}.`;
    const keyString = getCombinedKeyString(keys, meta, { target, indecies });
    return `${prefix}${keyString}`;
  }
  if (optionKey === "list") {
    return "state.hints.";
  }
  if (optionKey === "codeeditor") {
    return "state.codeeditors";
  }
  if (optionKey === "nodeeditor") {
    return "state.nodeeditors";
  }
  return undefined;
};

export const extractFunctions = (target, values) => {
  const keys = Object.keys(target);
  keys.forEach(key => {
    if (isArray(target[key])) {
      target[key].forEach(each => {
        extractFunctions(each, values);
      });
    } else if (isObject(target[key]) && key === "properties") {
      extractFunctions(target[key], values);
    } else if (isObject(target[key]) && target[key].hasOwnProperty("type")) {
      if (target[key].type === "Function") {
        values.push(target[key].value);
      }
    }
  });
};

export const ComponentsList = [
  "Button",
  "Input",
  "Link",
  "Text",
  "Uploader",
  "DateRange",
  "Number",
  "Select",
  "Radio",
  "ComboBox",
  "Quantity",
  "Amount",
  "CheckBox",
  "Status",
  "TextArea",
  "Date",
  "CodeEditor",
  "ScannerButton",
  "Image"
].sort();

export const TypeList = [
  "String",
  "Number",
  "Boolean",
  "Function",
  "Path",
  "Array"
].sort();
