import React from "react";
import { ComboBox, ComboBoxItem } from "@ui5/webcomponents-react";

const FtComboBox = props => {
  const { items, properties, style, value } = props;

  return (
    <ComboBox {...properties} style={style} value={value}>
      {items.list.map(item => (
        <ComboBoxItem text={item.text} key={item.key} />
      ))}
    </ComboBox>
  );
};

export default FtComboBox;
