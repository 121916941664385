import React from "react";
import { IColumnData } from "@/types/table";
import ComponentBuilder from "@/builders/common/ComponentBuilder";

interface IColumnBuilderProps {
  data: IColumnData;
  tableName: string;
  slot?: string;
}

const ColumnBuilder = (props: IColumnBuilderProps) => {
  const { data, tableName } = props;
  return (
    <ComponentBuilder
      slot="columns"
      name={`${tableName}_columns`}
      type="TableColumn"
      {...data}
    >
      {data.text}
    </ComponentBuilder>
  );
};

export default ColumnBuilder;
