import React from "react";
import { ICodeEditor, IConstructorProps } from "@/types/common";
import CodeEditorIterator from "./Iterator";

const CodeEditorConstructor = (props: IConstructorProps) => {
  const { data } = props;
  if (!data.codeeditor) return;
  const codeeditor = (data.codeeditor.hasOwnProperty("value") ||
  data.codeeditor.hasOwnProperty("properties")
    ? { codeeditor: data.codeeditor }
    : data.codeeditor) as { [key: string]: ICodeEditor };
  return <CodeEditorIterator codeEditor={codeeditor} />;
};

export default CodeEditorConstructor;
