import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "@bsgp/lib-date";

const initialState = {
  list: []
};

const viewerSlice = createSlice({
  name: "viewerSlice",
  initialState,
  reducers: {
    _updatedList: (state, action) => {
      const { editedList } = action.payload;

      state.list = editedList.map(item => {
        return {
          ...item,
          updatedAt: DateTime(item.updatedAt).formatted
        };
      });
    },
    _onSelect: (state, action) => {
      console.log("slice onSelect is working");

      return state;
    }
  }
});

export const viewerActions = viewerSlice.actions;
export default viewerSlice;
