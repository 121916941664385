import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import Config, { loadConfiguration } from "@bsgp/lib-config";
import { tryit, compare } from "@bsgp/lib-core";

import { doingValidation, getPathsForCurrentUser } from "actions/adir";
import {
  asyncSignOut,
  newSystemID,
  setAlterSystems,
  getIdpRedirected,
  selectLogInSystem
} from "actions/user";
import { addError } from "actions/ui5";
import renderNoticeDialog from "actions/renderNoticeDialog";

import AuthRoutes from "components/AuthRoutes";
import LoginPage from "components/login";
import googleLogin from "components/loginGoogle";
import Validation from "components/manage_adir/Validation";
import ValidationError from "components/manage_adir/ValidationError";

class Auth extends Component {
  constructor(props) {
    super(props);

    // const version = {
    //   SOURCEVERSION: process.env.REACT_APP_CODEBUILD_SOURCE_VERSION,
    //   TRIGGER: process.env.REACT_APP_CODEBUILD_WEBHOOK_TRIGGER,
    //   INITIATOR: process.env.REACT_APP_CODEBUILD_INITIATOR
    // };

    // console.log("init Auth", version);

    if (!this.checkQueryString(props)) {
      props.dispatch(asyncSignOut());
    }

    this.checkSystemID();

    const { history } = props;
    this.lastLocation = history.location;

    this.unregisterHistoryListner = history.listen((location, action) => {
      if (tryit(() => location.state.ignoreRelocation) === true) {
        console.warn("ignored new location by ignoreRelocation");
        return;
      }
      const { dispatch } = this.props;
      dispatch(doingValidation(), false); // URL이 변하면 validation을 다시 할수 있도록 함;

      console.log("%cLocation has been changed =>", "color:red;", location);
      console.log("%cLast location was =>", "color:red;", this.lastLocation);
      const queryParams = new URLSearchParams(location.search);
      const lastQueryParams = new URLSearchParams(this.lastLocation.search);

      this.appendUSP(queryParams, "s", lastQueryParams);
      this.appendUSP(queryParams, "u", lastQueryParams);

      const newQueryString = "?" + queryParams.toString();
      const newLocation = {
        ...location,
        search: newQueryString
      };
      this.lastLocation = newLocation;

      console.log(
        "neqQueryString:",
        newQueryString,
        "location.search:",
        location.search
      );
      // if (newQueryString === "?" && location.search === "") {
      //   console.log("ignore new location", newLocation);
      // } else {
      //   newQueryString !== location.search && history.replace(newLocation);
      // }
    });
  }

  checkSystemID() {
    const { dispatch, currentUser, history } = this.props;
    const queryString = history.location.search;

    const queryParams = new URLSearchParams(queryString);

    if (!queryParams.keys().next().value) {
      return;
    }

    // Handling "System ID" from querystring

    const sString = queryParams.get("s");

    // sString && queryParams.delete("s");

    // eslint-disable-next-line no-unused-vars
    if (sString) {
      const [sSystemID, sPartnerID] = sString.split("@");

      if (sSystemID && sSystemID !== currentUser.systemID) {
        dispatch(newSystemID({ systemID: sSystemID, partnerID: sPartnerID }));
      }
    }

    const system = queryParams.get("system");
    if (system) {
      const systemArr = system.split(",");
      const alterSystems = systemArr
        .map(system => system.split("@"))
        .map(([systemID, partnerID]) => ({ systemID, partnerID }));

      if (systemArr.length > 0) {
        if (
          (!currentUser.systemID && !currentUser.partnerID) ||
          alterSystems.filter(
            sys =>
              sys.systemID === currentUser.systemID &&
              sys.partnerID === currentUser.partnerID
          ).length === 0
        ) {
          dispatch(selectLogInSystem(systemArr[0]));
        }
      }

      dispatch(
        setAlterSystems({
          systems: alterSystems
        })
      );
    }

    const idpRedirected = queryParams.get("idp-redirect");
    if (idpRedirected) {
      dispatch(getIdpRedirected(idpRedirected));
    }

    // Handling "User ID" from querystring

    // const userID = queryParams.get("u");

    // userID && queryParams.delete("u");

    // if (userID && currentUsername !== userID) {
    //   dispatch(newUser(userID));
    // }

    // return "?" + queryParams.toString();
  }

  checkQueryString(props) {
    const { history, currentUser, dispatch } = props;

    const { systemID, partnerID, id } = currentUser;

    const { location } = history;
    const queryParams = new URLSearchParams(location.search);
    const sidFromQuery = queryParams.get("s");
    const uidFromQuery = queryParams.get("u");

    const message = queryParams.get("message");
    if (message) {
      dispatch(addError(decodeURIComponent(message)));
    }

    if (
      (sidFromQuery && sidFromQuery !== `${systemID}@${partnerID}`) ||
      (uidFromQuery && uidFromQuery !== id)
    ) {
      return false;
    } else if (message) {
      return false;
    } else {
      return true;
    }
  }

  hasSU(location) {
    const queryParams = new URLSearchParams(location.search);
    const sidFromQuery = queryParams.get("s");
    const uidFromQuery = queryParams.get("u");

    if (sidFromQuery || uidFromQuery) {
      return true;
    }
    return false;
  }

  appendUSP(usp, key, lastUSP) {
    const value = lastUSP.get(key);
    const preValue = usp.get(key);
    if (value) {
      preValue !== value && usp.append(key, value);
    } else {
      preValue && usp.delete(key);
    }
  }

  loadConfiguration = () => {
    return loadConfiguration([Config.id.API_MAP, Config.id.UNIT, Config.id.UI]);
  };

  componentDidMount = () => {
    const { is_authenticated, currentUser, dispatch } = this.props;

    if (is_authenticated && currentUser.pathLoaded !== true) {
      dispatch(getPathsForCurrentUser(currentUser));
    }
    if (is_authenticated) {
      this.loadConfiguration().then(() => {
        dispatch(renderNoticeDialog(this.props));
      });
      Sentry.setUser({
        email: currentUser.email || "NoEmail",
        username: currentUser.id,
        id: [
          currentUser.partnerID || "NoPartnerId",
          currentUser.systemID,
          currentUser.id
        ].join("/")
      });
    }
  };

  componentDidUpdate = prevProps => {
    const { dispatch } = this.props;
    const pUser = prevProps.currentUser;
    const nUser = this.props.currentUser;
    // console.log("compare nUser:", nUser.is_authenticated);
    // console.log("compare pUser:", pUser.is_authenticated);

    if (nUser.is_authenticated === true && pUser.is_authenticated === false) {
      this.loadConfiguration().then(() => {
        dispatch(renderNoticeDialog(this.props));
      });
      if (nUser.pathLoaded !== true) {
        dispatch(getPathsForCurrentUser(nUser));
      }
    } else if (nUser.is_authenticated === true) {
      if (!compare([nUser, pUser], ["id", "partnerID", "systemID"])) {
        this.loadConfiguration();
      }
    }
  };

  componentWillUnmount = () => {
    this.unregisterHistoryListner();
  };

  render() {
    const { history, is_authenticated, currentUser } = this.props;

    if (is_authenticated && this.checkQueryString(this.props)) {
      if (history.location.pathname === "/login") {
        return (
          <Redirect
            to={{
              pathname: "/gotoFromOrHome",
              state: history.location.state,
              search: history.location.search
            }}
          />
        );
      } else if (this.hasSU(history.location)) {
        return (
          <Redirect
            to={{
              pathname: history.location.pathname,
              state: {
                ignoreRelocation: true
              }
            }}
          />
        );
      } else {
        return (
          <div id="react_content">
            <ValidationError>
              {/* {loading && <Loading />} */}
              <Suspense fallback={<Validation />}>
                {!this.props.loadingConfig && currentUser.pathLoaded && (
                  <AuthRoutes currentUser={currentUser} />
                )}
              </Suspense>
            </ValidationError>
          </div>
        );
      }
    } else {
      const googleLogIn = history.location.pathname === "/login/google";
      let googleState = false;
      if (history.location.state && history.location.state.id_token) {
        // GOOGLE 로그인을 통해 user 정보를 받았는지 확인
        googleState = true;
      }
      if (/\/login[/]{0,1}$/.test(history.location.pathname)) {
        return (
          <Switch>
            <Route exact path={`/login`} component={LoginPage} />
          </Switch>
        );
      } else if (googleLogIn && googleState) {
        return (
          <Switch>
            <Route exact path={`/login/google`} component={googleLogin} />
          </Switch>
        );
      } else {
        return (
          <Redirect
            to={{
              pathname: `/login`,
              state: {
                from: `${history.location.pathname}${history.location.search}`
              },
              search: history.location.search
            }}
          />
        );
      }
    }
  }
}

function mapStateToProps(state) {
  const loadingConfig = tryit(() =>
    state.getList("Configuration", "isLoading")
  );
  return {
    is_authenticated: state.user.currentUser.is_authenticated,
    userID: state.user.currentUser.id,
    currentUser: state.user.currentUser,
    loadingConfig
    // loading: state.wsProcess.loading
  };
}

export default compose(connect(mapStateToProps), withRouter)(Auth);
