import React from "react";
import { TabContainer, Tab } from "@/components";
import { ICodeEditor } from "@/types/common";
import { TabContainerBackgroundDesign } from "@ui5/webcomponents-react";
import clsx from "clsx";
import CodeEditorBuilder from "./Builder";
import { CodeEditorContextProvider } from "@/contexts/codeEditorContext";

interface ICodeEditorIteratorProps {
  codeEditor: { [key: string]: ICodeEditor };
}

function CodeEditorIterator(props: ICodeEditorIteratorProps) {
  const { codeEditor } = props;
  const ceKeys = Object.keys(codeEditor);
  const codeEditorList = Object.values(codeEditor);
  const hasMultiCe = ceKeys.length > 1;

  const codeEditorNameList = ceKeys.map((ceKey, index) => {
    return codeEditor[ceKey].title
      ? codeEditor[ceKey].title
      : `code-editor-${index}`;
  });
  return hasMultiCe ? (
    <TabContainer
      style={{ height: "100%" }}
      fixed={true}
      contentBackgroundDesign={TabContainerBackgroundDesign.Transparent}
      className={clsx("noBottomLine", "ft-height-100-for-codeeditor")}
      onTabSelect={oEvent => {
        const selectedIndex = oEvent.detail.tabIndex;
        const selectHandler = codeEditorList[selectedIndex].onSelect;
        if (selectHandler) {
          const selectedKey = codeEditorNameList[selectedIndex];
          selectHandler({ tabKey: selectedKey });
        }
      }}
    >
      {ceKeys.map((ceKey, index) => {
        const ceObject = codeEditor[ceKey];
        const ceId = ceKey;
        return (
          <Tab
            key={ceId}
            text={ceObject.title}
            style={{ overflow: "hidden" }}
            selected={ceObject.selected}
          >
            <CodeEditorContextProvider>
              <CodeEditorBuilder {...ceObject} ceId={ceId} />
            </CodeEditorContextProvider>
          </Tab>
        );
      })}
    </TabContainer>
  ) : (
    <CodeEditorContextProvider>
      <CodeEditorBuilder {...codeEditorList[0]} ceId={ceKeys[0]} />
    </CodeEditorContextProvider>
  );
}

export default CodeEditorIterator;
