import React from "react";
import { tryit } from "@bsgp/lib-core";
import { constructTableData } from "@bsgp/form-and-table/common";
import TableWrapperBuilder from "@/builders/table/TableWrapperBuilder";
import { IConstructorProps } from "@/types/common";
import { refineEvent } from "@/lib/refineEvent";
import { ConstructTableDataType } from "@/types/table";
import { propsRef } from "@/ref";
import TableBuilder from "./TableBuilder";

const TableConstructor = (props: IConstructorProps) => {
  const { data } = props;
  const fn = propsRef.current.fn;

  if (!fn || !data.table) return;

  const wrapSingleTable = tryit(() => data.wrapSingleTable);
  const tableDataList = constructTableData(data.table, fn, "", {
    isReact: true,
    refineFunc: refineEvent
  }) as ConstructTableDataType;
  const doWrapTables = tableDataList.length > 1 || wrapSingleTable === true;

  return doWrapTables ? (
    <TableWrapperBuilder tableDataList={tableDataList} />
  ) : (
    tableDataList.map(tableObject => {
      return <TableBuilder key={tableObject.name} {...tableObject} />;
    })
  );
};

export default TableConstructor;
