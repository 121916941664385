import React from "react";
import { isTruthy, isArray } from "@bsgp/lib-core";
import { ICommonProps } from "@/types/common";
import HistoryBuilder from "./Builder";

const HistoryConstructor = (props: ICommonProps) => {
  const { data, title, history } = props;

  const historyLinks = isArray(data.historyLinks) ? data.historyLinks : [];

  if (!isTruthy(historyLinks)) return;

  return (
    <div style={{ padding: "0.5rem 1rem" }}>
      <HistoryBuilder
        historyLinks={historyLinks}
        history={history}
        title={title}
      />
    </div>
  );
};

export default HistoryConstructor;
