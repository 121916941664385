import React, { memo, useMemo } from "react";
import DialogV2Builder from "./DialogV2Builder";
import { IDialogData } from "@/types/dialog";

interface IBuildDialogProps {
  dialogDataMap: {
    [key: string]: IDialogData;
  };
}

const DialogV2BuildIterator = (props: IBuildDialogProps) => {
  const { dialogDataMap } = props;

  return useMemo(
    () =>
      Object.entries(dialogDataMap).map(([key, dialogObject]) => {
        return (
          <DialogV2Builder
            key={`dialog-${key}`}
            {...dialogObject}
            dialogId={key}
          />
        );
      }),
    [dialogDataMap]
  );
};

export default memo(DialogV2BuildIterator);
