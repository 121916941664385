export const form = {
  key: "",
  title: "",
  default: false,
  sizeV2: { value: "true", type: "Boolean", seq: 0 },
  properties: {},
  containers: []
};

export const container = {
  key: "",
  title: "",
  properties: {},
  fields: []
};

export const field = {
  key: "",
  label: "",
  noWrap: { value: "true", type: "Boolean", seq: 0 },
  components: []
};

export const component = {
  properties: {},
  valueHelpV2: {}
};

export const header = {
  key: "",
  text: "",
  type: "",
  properties: {},
  buttons: []
};

export const button = {
  key: "",
  text: "",
  type: "",
  properties: {},
  isAction: false
};

export const table = {
  key: "",
  title: "",
  isSearch: false,
  properties: {},
  toolbars: [],
  columns: [],
  items: []
};

export const column = {
  key: "",
  text: "",
  components: []
};

export const dialog = {
  key: "",
  title: "",
  forms: [],
  tables: [],
  codeeditors: [],
  nodeeditors: [],
  properties: {}
};

export const toolbar = {
  key: "",
  text: "",
  type: "",
  properties: {},
  buttons: []
};

export const codeeditor = {
  key: "",
  title: "",
  components: [],
  properties: {}
};

export const nodeeditor = {
  key: "",
  title: "",
  components: [],
  properties: {}
};
