import React from "react";
import { connect } from "react-redux";
import { makeid, tryit } from "@bsgp/lib-core";
import { withRouter } from "react-router-dom";

import { Button, Menu, MenuItem, ButtonDesign } from "@ui5/webcomponents-react";

function MenuButton(props) {
  const {
    paths,
    history,
    theme,
    is_authenticated,
    showHeaderButtonText,
    menuButtonText
  } = props;

  // const [menuIsOpen, setMenuIsOpen] = useState(false);

  const noMenu = paths.filter(path => !path.hideOnMenu).length === 0;
  if (noMenu) {
    paths.push({
      name: "No Menu",
      disabled: true
    });
  }

  if (!is_authenticated) {
    return false;
  }

  return (
    <div slot={props.slot}>
      <Button
        id={"openMenuBtn"}
        className={theme}
        design={ButtonDesign.Transparent}
        icon="menu2"
        title="Menu"
        onClick={() => {
          // setMenuIsOpen(true);
          document
            .querySelector("#mainMenu")
            .showAt(document.querySelector("#openMenuBtn"));
        }}
      >
        {showHeaderButtonText ? menuButtonText || "메뉴" : ""}
      </Button>
      <Menu
        id={"mainMenu"}
        // opener={"openMenuBtn"}
        // open={menuIsOpen}
        // style={{ width: "110%" }}
        onItemClick={event => {
          const { url } = event.detail.item.dataset;
          if (url) {
            setTimeout(() => {
              history.push(url);
            }, 0);
          }
        }}
        onAfterClose={() => {
          // setMenuIsOpen(false);
        }}
      >
        <MenuItems paths={paths} />
      </Menu>
    </div>
  );
}

function mapStateToProps(state) {
  const config = tryit(() => state["api-Configuration"].merged.ui.result) || {};

  return {
    showHeaderButtonText: config.ShowHeaderButtonText,
    menuButtonText: config.MenuButtonText,
    theme: state.user.currentUser.theme,
    is_authenticated: state.user.currentUser.is_authenticated,
    paths: state.user.currentUser.paths || []
  };
}

export default withRouter(connect(mapStateToProps)(MenuButton));

function hasSubMenuItems(path) {
  return path.subPaths && path.subPaths.length > 0;
}

function isDisabled(path) {
  if (path.disabled === true) {
    return path.disabled;
  }

  if (!path.url && !hasSubMenuItems(path)) {
    return true;
  }

  return false;
}

function MenuItems(props) {
  const { paths } = props;
  const availablePaths = paths.filter(path => !path.hideOnMenu);

  return availablePaths.map(path => {
    const disabled = isDisabled(path);

    return (
      <MenuItem
        key={makeid(10)}
        icon={path.icon}
        text={path.name}
        data-url={path.url}
        additionalText={path.description}
        disabled={disabled}
      >
        {!disabled && hasSubMenuItems(path) ? (
          <MenuItems paths={path.subPaths} />
        ) : null}
      </MenuItem>
    );
  });
}
