import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { FormTable, formTable } from "@bsgp/form-and-table";
import { FormTable as FormTableReact } from "@bsgp/ft-react";
import { isTruthy, tryit } from "@bsgp/lib-core";
import { apiHub } from "@bsgp/lib-api";
import * as controller from "./controller";
import {
  getProperties,
  executeFunction,
  addObjects,
  checkPreviewMode
} from "./modules";
import builderSlice from "../builder/slice";
import rendererSlice from "./slice";
import { addError } from "actions/ui5";

const initPage = (meta, props) => {
  const { _dispatch } = props;

  if (isTruthy(meta.forms)) {
    const firstSelectedFormId = meta.forms[0].key;

    _dispatch(
      rendererSlice.actions._selectFormId({ formKey: firstSelectedFormId })
    );
  }

  _dispatch(rendererSlice.actions._initialSpread({ meta }));

  executeFunction(props, {
    uid: "initialization",
    functions: meta.functions,
    isAsync: true
  });
};

const Renderer = props => {
  const { _state, _dispatch, match, history, location, dispatch } = props;
  const { entryPath } = match.params;

  const endPoint = "/g/lc_ui5";
  const { meta, data } = _state;

  const builder = formTable({ wrapForms: meta.wrapForms });

  useEffect(() => {
    if (data.messages) {
      setTimeout(() => {
        data.messages.forEach(msg => {
          dispatch(addError(msg));
        });
      }, 10);
      _dispatch(
        rendererSlice.actions._overwriteState(draft => {
          delete draft.messages;
        })
      );
    }
  }, [data.messages]);

  useEffect(() => {
    if (entryPath !== undefined) {
      apiHub.get(
        endPoint,
        { path: `/${entryPath}` },
        {
          afterSucceed: res => {
            _dispatch(builderSlice.actions._getMetaData(res));
            _dispatch(
              builderSlice.actions._updateMetaProperty({
                key: "currentPath",
                value: entryPath,
                extra: true
              })
            );
          },
          afterFailed: () => {
            if (checkPreviewMode(match)) {
              history.replace(`/${entryPath}`, location.state);
            } else history.replace("/"); // preview 모드가 아닌 경우 홈으로 리다이렉팅
          }
        }
      );
    }

    return () => {
      _dispatch(
        builderSlice.actions._updateMetaProperty({
          key: "currentPath",
          value: "",
          extra: true
        })
      );
      _dispatch(rendererSlice.actions._initAll());
    };
  }, [entryPath]);

  useEffect(() => {
    if (meta.extra.currentPath === entryPath) {
      initPage(meta, props);
    }
  }, [meta.extra.currentPath]);

  useEffect(() => {
    meta.dialogs
      .filter(each => each.ignore !== true)
      .forEach(dialog => {
        if (
          data.dialogs[dialog.key]?.isOpen === true &&
          data.dialogs[dialog.key]?.initialized !== true
        ) {
          _dispatch(
            rendererSlice.actions._updateDialogData({
              dialogKey: dialog.key,
              key: "initialized",
              value: true
            })
          );

          initPage(dialog, props);
        }
      });
  }, [data.dialogs]);

  if (entryPath === meta.extra.currentPath) {
    if (isTruthy(meta.headers)) {
      const { headers } = meta;

      // todo: formkey 넣어주기
      headers
        .filter(each => each.ignore !== true)
        .forEach(header => {
          const { buttons } = header;

          buttons
            .filter(each => each.ignore !== true)
            .forEach(button => {
              if (button.isAction) {
                builder.addFooterAction(`${button.key}`, {
                  text: button.text,
                  ...getProperties(button, {
                    props,
                    builder,
                    entryPath
                  })
                });
              } else {
                builder.addFooterButton(`${button.key}`, {
                  text: button.text,
                  ...getProperties(button, {
                    props,
                    builder,
                    entryPath
                  })
                });
              }
            });
        });
    }

    addObjects(builder, {
      forms: meta.forms,
      tables: meta.tables,
      codeeditors: meta.codeeditors,
      nodeeditors: meta.nodeeditors,
      props,
      functions: meta.functions,
      entryPath
    });

    if (isTruthy(meta.dialogs)) {
      meta.dialogs
        .filter(each => each.ignore !== true)
        .forEach((dialog, dIdx) => {
          const dialogBuilder = formTable();

          addObjects(dialogBuilder, {
            forms: dialog.forms,
            tables: dialog.tables,
            codeeditors: dialog.codeeditors,
            nodeeditors: dialog.nodeeditors,
            functions: dialog.functions,
            props,
            entryPath
          });

          const isValueHelp = !data.dialogs[dialog.key];
          const customProps = getProperties(dialog, {
            props,
            builder,
            entryPath
          });
          const dialogProps = {
            ...customProps,
            isOpen: isValueHelp
              ? undefined
              : tryit(() => data.dialogs[dialog.key].isOpen),
            onClose: fn => fn.onClose(dialog.key),
            properties: {
              ...customProps.properties,
              title: dialog.title
            },
            ftData: dialogBuilder.done()
          };
          if (dialog.onConfirm) {
            dialogProps.onCancel = fn => fn.onCancel(dialog.key);
          }
          builder.addDialog(dialog.key, dialogProps);
        });
    }
  }

  if (meta.renderAsReact === true) {
    return (
      <FormTableReact
        {...props}
        fn={controller}
        title={meta.title}
        data={builder.done()}
      ></FormTableReact>
    );
  } else {
    return (
      <FormTable
        {...props}
        fn={controller}
        title={meta.title}
        data={builder.done()}
      ></FormTable>
    );
  }
};

export default compose(withRouter)(Renderer);
