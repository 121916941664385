import React from "react";
import { ITableProperties } from "@/types/table";
import { TableCell } from "@/components";
import { getNewName } from "@bsgp/form-and-table/common";
import CellBuilder from "./CellBuilder";

function TableCellBuilder(
  props: ITableProperties & {
    rowData: object;
  }
) {
  const { name, columnProperties, rowData } = props;
  const { columns } = columnProperties;
  return columns.map((col, idx) => {
    const value = rowData[col.name] || rowData[col.value];
    const cellName = getNewName([name, col.name].join("-"));
    const hAlign = col.properties.hAlign || "Left";
    return (
      <TableCell
        {...props}
        name={name}
        key={`${cellName}-TableCell-${idx}`}
        style={{
          textAlign: hAlign.toLowerCase()
        }}
      >
        <CellBuilder
          column={col}
          value={value}
          cellName={cellName}
          rowData={rowData}
        />
      </TableCell>
    );
  });
}

export default TableCellBuilder;
