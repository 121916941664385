import { extension } from "mime-types";
import { byId, tryit } from "ui5-lib-rc";

import { addError } from "actions/ui5";

export const NEW_TOKEN = "NEW_TOKEN";
export const newToken = (token, cookie) => {
  return {
    type: NEW_TOKEN,
    token,
    cookie
  };
};

export const NEW_WS_INSTANCE = "NEW_WS_INSTANCE";
export const newWSInstance = ws => {
  return {
    type: NEW_WS_INSTANCE,
    ws
  };
};
export const REMOVE_WS_INSTANCE = "REMOVE_WS_INSTANCE";
export const removeWSInstance = ws => {
  return {
    type: REMOVE_WS_INSTANCE,
    ws
  };
};

export const ADD_WS_REQUEST = "ADD_WS_REQUEST";
export const addWSRequest = request => {
  return {
    type: ADD_WS_REQUEST,
    request
  };
};

export const INIT_DATA_SPLITTED = "INIT_DATA_SPLITTED";
export const initDataSplitted = () => {
  return {
    type: INIT_DATA_SPLITTED
  };
};

export const CLEAR_WS_PROCESS = "CLEAR_WS_PROCESS";
export const clearWSProcess = processID => {
  return {
    type: CLEAR_WS_PROCESS,
    processID
  };
};

export const UPDATE_WS_COUNT = "UPDATE_WS_COUNT";
export const updateWSCount = processID => {
  return {
    type: UPDATE_WS_COUNT,
    processID
  };
};

export const UPDATE_WS_PROCESS = "UPDATE_WS_PROCESS";
export const updateWSProcess = (processID, processItem) => {
  return {
    type: UPDATE_WS_PROCESS,
    processID,
    processItem
  };
};

export const GET_SPLITTED_DATA = "GET_SPLITTED_DATA";
const getSplittedData = dataSplitted => {
  return {
    type: GET_SPLITTED_DATA,
    dataSplitted
  };
};

export const runProcess = function(processID) {
  const args = leakArguments(arguments, 1);
  return (dispatch, getState) => {
    const processes = getState().api.wsProcess;
    Object.keys(processes)
      .filter(key => (processID ? processID === key : true))
      .forEach(key => {
        const prc = processes[key];
        if (prc.count === prc.items.length) {
          // process finished

          dispatch(clearWSProcess(key));
        } else {
          byId("app").setBusy(true);
          if (prc.items[prc.count].status === "readyToSend") {
            dispatch(
              updateWSProcess(
                key,
                Object.assign({}, prc.items[prc.count], { status: "sending" })
              )
            );
            dispatch(prc.items[prc.count].reqHandler(...args));
          }
        }
      });

    Object.keys(getState().api.wsProcess).length > 0 ||
      byId("app").setBusy(false);
  };
};

export const reqGetToken = ({
  ws,
  processID = "",
  urlPath = "",
  sapLanguage = "en"
}) => {
  return (dispatch, getState) => {
    const { odataBaseURL, headers } = prepareConnectingBydesignOdata(getState);

    const odata_url = [odataBaseURL, urlPath].join("");
    const actionConfig = {
      action: "requestodata",
      toDo: "getToken",
      processID: processID
    };
    sendToWebSocket(
      ws,
      {
        httpMethod: "GET",
        queryStringParameters: {
          url: convert_url(odata_url, {
            "sap-language": sapLanguage, // user.lang,
            $format: "json",
            $top: "1"
          }),
          headers: {
            ...headers,
            "x-csrf-token": "fetch"
          }
        }
      },
      {
        dispatch,
        actionConfig,
        getState,
        message: "Fetching token from ByDesign"
      }
    );
  };
};
export const resGetToken = (json_data, callback = {}) => {
  return (dispatch, getState) => {
    const jsonHeader = tryit(() => {
      return json_data.headers;
    });
    const token = tryit(() => {
      return jsonHeader["x-csrf-token"];
    });
    console.log("token: ", token);
    const cookie = tryit(() => {
      return jsonHeader["my-cookie"];
    });

    if (token) {
      dispatch(newToken(token, cookie));
      callback.afterSucceed && callback.afterSucceed();
    } else {
      dispatch(addError(["Failed to get valid token"]));
      callback.fail && callback.fail();
    }
  };
};
const leakArguments = function(args, excludeLength) {
  const elen = excludeLength || 0;
  return Array.prototype.slice.call(args, elen);
};

const htmlError = (htmlString, statusCode) => {
  const htmlBody = window.jQuery(window.jQuery.parseHTML(htmlString));
  if (htmlBody.length > 0) {
    const cnt_loginForm = htmlBody.find("[name='loginForm']").length;
    if (cnt_loginForm > 0) {
      return {
        hasError: true,
        error: {
          message: "Login Failed",
          description: [
            `Response Status ${statusCode}.`,
            "You need to log in.",
            "If this message represented right after tring to log in,",
            "then please check your ID and Password if they are valid."
          ].join(" ")
        }
      };
    } else {
      return {
        hasError: true,
        error: {
          is_markup: true,
          message: "Error occurred",
          description: `Response Status ${statusCode}. ${htmlString}`
        }
      };
    }
  }
};

const jsonError = function(jsonString, statusCode) {
  function check_body(json) {
    return "body" in json ? json.body : json;
  }
  const jsonBody =
    typeof jsonString === "string"
      ? check_body(JSON.parse(jsonString))
      : check_body(jsonString);

  const msg =
    tryit(() => {
      const innerMsg = jsonBody.error.message;
      return typeof innerMsg === "string" ? innerMsg : null;
    }) ||
    tryit(() => {
      return jsonBody.error.message.value;
    }) ||
    tryit(() => {
      return jsonBody.errorMessage;
    });

  const code = tryit(() => {
    return jsonBody.error.code;
  });

  if (msg) {
    return {
      hasError: true,
      error: {
        message: "Error Occurred",
        description: `Response Status: ${statusCode}. Code: ${code}. ${msg}`
      }
    };
  }
};
const handleStackTrace = function(res) {
  const stackTrace =
    tryit(() => {
      return res.body.stackTrace;
    }) ||
    tryit(() => {
      return res.stackTrace;
    });
  const errorType =
    tryit(() => {
      return res.body.errorType;
    }) ||
    tryit(() => {
      return res.errorType;
    });
  const errorMessage =
    tryit(() => {
      return res.body.errorMessage;
    }) ||
    tryit(() => {
      return res.errorMessage;
    });

  if (!stackTrace) {
    // return {
    //  hasError: true,
    //  error: {
    //    is_markup: false,
    //    message: `${errorType}: ${errorMessage}`
    //  }
    // };
  } else {
    return {
      hasError: true,
      error: {
        is_markup: true,
        message: `${errorType}: ${errorMessage}`,
        description:
          stackTrace &&
          stackTrace
            .map(st => {
              const markup = ["<div>"];
              if (typeof st === "string") {
                markup.push(
                  ...[
                    `<p style=${"font-size:0.8rem;line-height:0.8rem;"}>`,
                    st,
                    `</p>`
                  ]
                );
              } else {
                markup.push(
                  ...st.map((std, idx) => {
                    if ([1, 2].filter(ii => idx === ii).length > 0) {
                      return "";
                    }

                    const lineNumber = tryit(() => {
                      return idx === 0 ? st[1] : 0;
                    }, 0);
                    const inMethod = tryit(() => {
                      return idx === 0 ? st[2] : 0;
                    }, 0);

                    return [
                      `<p style=${
                        idx === 0
                          ? "font-weight:bold;"
                          : "font-size:0.8rem;line-height:0.5rem;"
                      }>`,
                      lineNumber > 0
                        ? `${std} - (line ${lineNumber} in ${inMethod})`
                        : std,
                      "</p>"
                    ].join("");
                  })
                );
              }
              markup.push("</div>");
              return markup.join("");
            })
            .join("")
      }
    };
  }
};

const checkError = function(res, body) {
  const internal_server_error = tryit(() => {
    return res.message;
  });
  if (internal_server_error === "Internal server error") {
    const connectionId = tryit(() => {
      return res.connectionId;
    });
    const requestId = tryit(() => {
      return res.requestId;
    });
    return {
      hasError: true,
      error: {
        message: internal_server_error,
        description: [
          "connectionId:",
          connectionId,
          "\n",
          "requestId:",
          requestId,
          "\n",
          "Some error occurred on the server, please contact administrator"
        ].join("")
      }
    };
  }

  const stackErr = handleStackTrace(res);
  if (stackErr) {
    return stackErr;
  }

  const contentType =
    tryit(() => {
      return extension(body.headers["content-type"]);
    }) || "json";

  if (body) {
    switch (contentType) {
      case "json":
        const jsonErr = jsonError(body, "");
        if (jsonErr) {
          return jsonErr;
        }
        break;
      case "html":
        const htmlErr = htmlError(body.body, "");
        if (htmlErr) {
          return htmlErr;
        }
        break;
      default:

      // pass
    }
  }

  return {
    hasError: false,
    error: null
  };
};
export function sendToWebSocket(ws, msg, options) {
  // if (!ws) {
  //   ws = options.getState && options.getState().wsProcess.wSocket;
  // }

  if (ws && ws.readyState === ws.OPEN) {
    options.actionConfig &&
      options.dispatch(
        updateWSProcess(options.actionConfig.processID, {
          message: options.message,
          reqArgs: options.reqArgs && leakArguments(options.reqArgs)
        })
      );
  }
  const stageName = process.env.REACT_APP_API_STAGE_NAME;

  if (!ws) {
    const ws = new WebSocket(
      `${process.env.REACT_APP_API_WS_PROXY_URL}/${stageName}`
    );

    ws.onopen = event => {
      console.log(event);
      sendToWebSocket(ws, msg, options);
    };
    ws.onerror = event => {
      console.log(event);
      options.dispatch(removeWSInstance(ws));
    };
    ws.onclose = event => {
      console.log(event);
      options.dispatch(removeWSInstance(ws));
    };
    ws.onmessage = event => {
      console.log("%conmessage:", "color:red", event.timeStamp);
      const json_data = tryit(() => {
        return JSON.parse(event.data);
      }, event.data);
      console.log("%conmessage:", "color:red", json_data);

      const waitingFor = tryit(() => {
        return json_data.waitingFor;
      });
      if (waitingFor) {
        options.dispatch(
          updateWSProcess(waitingFor.processID, {
            action: waitingFor.action,
            toDo: waitingFor.toDo,
            status: "waiting"
          })
        );
        return;
      }

      const final_callback = () => {
        byId("app").setBusy(false);
        options.dispatch(clearWSProcess(json_data.processID));
      };

      const json_body = convertToJson(
        json_data,
        options.getState,
        options.dispatch
      );
      console.log("%cjson_body", "color:red", json_body);

      const { hasError, error } = checkError(json_data, json_body);
      console.log(hasError);
      if (hasError) {
        final_callback();
        options.dispatch(addError([error]));
        return;
      }

      if (!json_body) {
        return;
      }

      const processes = options.getState().api.wsProcess;
      console.log(json_data.processID);
      const cCount = processes[json_data.processID].count;
      const cProcessItem = processes[json_data.processID].items[cCount];
      const thunkAC = cProcessItem.resHandler;
      const resCallback = cProcessItem.resCallback;
      const reqArgs = cProcessItem.reqArgs;
      console.log("reqArgs:", reqArgs);

      const nextURL = tryit(() => {
        const jsonBody = JSON.parse(json_body.body);
        return jsonBody.d.__next;
      });

      const totalCount = tryit(() => {
        const jsonBody = JSON.parse(json_body.body);
        return jsonBody.d.__count;
      });

      const thisCount = tryit(() => {
        const jsonBody = JSON.parse(json_body.body);
        return jsonBody.d.results.length;
      });
      console.log("nextURL:", nextURL);
      if (nextURL) {
        const actionConfig = {
          action: json_data.action,
          toDo: json_data.toDo,
          processID: json_data.processID
        };
        const headers = msg.queryStringParameters.headers;
        options.dispatch(
          updateWSProcess(json_data.processID, {
            totalCount,
            count: cProcessItem.count
              ? cProcessItem.count + thisCount
              : thisCount
          })
        );
        options.dispatch(initDataSplitted());
        options.dispatch(reqNextOdata({ ws, nextURL, actionConfig, headers }));
      } else {
        options.dispatch(updateWSCount(json_data.processID));
      }

      let new_resCallback = {};
      if (resCallback) {
        new_resCallback = { ...resCallback };
      }
      if (!nextURL) {
        if (new_resCallback.afterSucceed) {
          new_resCallback.afterSucceed = function() {
            resCallback.afterSucceed(...leakArguments(arguments));
            options.dispatch(
              runProcess(json_data.processID, ...leakArguments(arguments))
            );
          };
        } else {
          new_resCallback.afterSucceed = function() {
            options.dispatch(
              runProcess(json_data.processID, ...leakArguments(arguments))
            );
          };
        }
        if (new_resCallback.fail) {
          new_resCallback.fail = () => {
            final_callback();
            resCallback.fail(...leakArguments(arguments));
          };
        } else {
          new_resCallback.fail = () => {
            final_callback();
          };
        }
      }

      options.dispatch(thunkAC(json_body, new_resCallback, reqArgs));
    };
    options.dispatch && options.dispatch(newWSInstance(ws));
  } else {
    /* if (options.dispatch) {
      const name = Math.random()
        .toString(36)
        .substring(2, 15);

      options.dispatch(
        newWSRequest({
          name,
          action: options.action,
          toDo: options.toDo,
          message: options.message,
          sending: true
        })
      );
    }*/

    if (ws.readyState === ws.CONNECTING) {
      setTimeout(() => {
        sendToWebSocket(ws, msg, options);
      }, 1000);
      return;
    }
    const jsonMSG = tryit(() => {
      const user = options.getState().user.currentUser;
      return {
        ...msg,
        ...options.actionConfig,
        stageName,
        headers: {
          ...(msg.headers || {}),

          "bsg-support-token": user.token,
          "bsg-support-session": user.session,
          "bsg-support-userID": user.id,
          "bsg-support-partnerID": user.partnerID,
          "bsg-support-systemID": user.systemID
        }
      };
    }, msg);
    console.log("%csending message:", "color:green", jsonMSG);
    const wsMSG = typeof msg === "string" ? msg : JSON.stringify(jsonMSG);

    ws.send(wsMSG);
  }
}

export const reqNextOdata = ({ ws, nextURL, actionConfig, headers }) => {
  return (dispatch, getState) => {
    sendToWebSocket(
      getState().api.ws,
      {
        ...actionConfig,
        httpMethod: "GET",
        queryStringParameters: {
          url: nextURL,
          headers
        }
      },
      {
        dispatch,
        getState,
        ...actionConfig,
        message: "Fetching for next more data"
      }
    );
  };
};

export function couldRunAfterSucceed(json_data, options = {}) {
  const jsonBody = tryit(() => {
    return JSON.parse(json_data.body);
  });
  if (jsonBody) {
    const dResults = tryit(() => {
      return jsonBody.d.results;
    }, null);

    const count = tryit(() => {
      return jsonBody.d.__count;
    }, 0);

    const nextURL = tryit(() => {
      return jsonBody.d.__next;
    }, null);

    if (dResults) {
      console.log("%cd_results", "color:red", dResults);
      if (Array.isArray(dResults)) {
        if (options.zero_count_is_okay) {
          return { okay: true, dResults, nextURL };
        } else if (count > 0) {
          return { okay: true, dResults, nextURL };
        } else {
          return { okay: false, dResults: [] };
        }
      } else {
        return { okay: true, dResults };
      }
    } else {
      return { okay: false, dResults: null };
    }
  } else {
    return { okay: false, dResults: null };
  }
}

export function convertToJson(json_data, getState, dispatch) {
  let json_body = null;

  if (json_data.splitted) {
    dispatch(getSplittedData(json_data));
    const dataSplitted = getState().api.dataSplitted[json_data.toDo];
    console.log(dataSplitted.length);
    if (dataSplitted.length === json_data.splitted.total) {
      json_body = atob(dataSplitted.join(""));
    } else {
      // return;
    }
  } else {
    if (json_data.toDo) {
      json_body = tryit(
        () => {
          return JSON.parse(json_data.body);
        },
        tryit(() => {
          return atob(json_data.body);
        }, json_data.body)
      );
    }
  }

  const status = json_data.splitted
    ? json_data.splitted.seq === json_data.splitted.total
      ? "received"
      : "receiving"
    : "received";
  dispatch(
    updateWSProcess(json_data.processID, {
      action: json_data.action,
      toDo: json_data.toDo,
      status,
      splitted: json_data.splitted
        ? {
            seq: json_data.splitted.seq,
            total: json_data.splitted.total
          }
        : null
    })
  );

  if (json_body) {
    try {
      if (json_data.xmlRaw) {
        // json_body = xmljsConvert.xml2js(json_body, { compact: true });
      } else {
        if (typeof json_body === "string") {
          json_body = JSON.parse(json_body);
        }
      }
      return json_body;
    } catch (ex) {
      console.log(json_body);
    }
  }
}

export function prepareConnectingBydesignOdata(getState, type) {
  const user = getState().user.currentUser;
  const odata_base_url = [
    "https://",
    user.pid || getState().user.pid,
    ".",
    type === "report"
      ? process.env.REACT_APP_BYD_REPORT_ODATA_PATH
      : process.env.REACT_APP_BYD_CUSTOM_ODATA_PATH
  ].join("");
  const api_url = process.env.REACT_APP_API_ODATA_PROXY_URL;
  const headers = {
    authorization: `Basic ${btoa(user.username + ":" + user.password)}`,
    accept: "application/json"
  };
  return { odataBaseURL: odata_base_url, apiURL: api_url, headers };
}

export function convert_url(baseURL, params) {
  const stringParams = Object.keys(params)
    .map(key => {
      const value = params[key];
      let part = "";

      part += key + "=";

      if (key === "$filter") {
        part += value.join("");
      } else {
        part += value;
      }
      return part;
    })
    .join("&");

  return `${baseURL}?${stringParams}`;
}
