import React, { CSSProperties, memo } from "react";
import { defined } from "@bsgp/lib-core";
import { Text } from "@ui5/webcomponents-react";
import clsx from "clsx";
import { ITextProps } from "@/types/component";

const defaultStyle: CSSProperties = {
  textAlign: "start"
};

const FtText = (props: ITextProps) => {
  const {
    value,
    tooltip,
    styleClasses,
    hiddenData,
    label,
    properties,
    children,
    rowData,
    style,
    ...restProps
  } = props;

  return (
    <Text
      title={defined(tooltip, value, "")}
      style={{ ...defaultStyle, ...style }}
      className={clsx(styleClasses)}
      emptyIndicator={true}
      renderWhitespace={true}
      {...restProps}
    >
      {getText(value) || getText(children)}
    </Text>
  );
};

function getText(targetVal) {
  if (typeof targetVal === "string") {
    return targetVal;
  }
  if (
    typeof targetVal === "object" &&
    "value" in targetVal &&
    typeof targetVal.value === "string"
  ) {
    return targetVal.value;
  }

  return "";
}
export default memo(FtText);
