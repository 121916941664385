import React, { useEffect } from "react";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "react-router-dom";
import { FormTable, formTable, ft } from "@bsgp/form-and-table";
import * as controller from "./controller";

const Viewer = props => {
  useEffect(() => {
    controller.getAllList({ getProps: () => props })();
  }, []);

  const builder = formTable({});

  const { _state } = props;
  const { list } = _state.viewer;

  builder
    .addFooterButton("builder", {
      value: "생성",
      icon: "sap-icon://create",
      onPress: fn => fn.moveToBuilder()
    })
    .addFooterButton("refresh", {
      value: "",
      isMain: false,
      icon: "sap-icon://refresh",
      onPress: fn => fn.getAllList
    })
    .addTable("list", {
      usePagination: true,
      onSort: fn => fn.onSortTable
    })
    .addToolbarSearch()
    .addColumn("id", {
      text: "ID",
      component: ft.Link({
        onPress: fn => fn.moveToBuilderWithPath()
      })
    })
    .addColumn("description", {
      text: "Description"
    })
    .addColumn("updatedAt", {
      text: "Updated At"
    })
    .addColumn("updatedBy", {
      text: "Updated By"
    })
    .addItems(list);

  return (
    <FormTable
      {...props}
      fn={controller}
      title={"List"}
      data={builder.done()}
    ></FormTable>
  );
};

export default compose(withRouter)(Viewer);
