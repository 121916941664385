import { functions as ftFunctions } from "@bsgp/form-and-table";
import { isFalsy } from "@bsgp/lib-core";
import { rendererActions } from "./slice";
import { executeFunction } from "./modules";

export const getMetaFuncContent = ({ getProps, returnContextKeys }) => ({
  formKey,
  tableKey,
  fieldKey,
  functions,
  componentKey,
  type,
  functionUid,
  conv,
  autoClose = false
}) => async oEvent => {
  const { _dispatch, _state } = getProps();

  const context = {
    componentKey
  };

  const newType = type.includes("-") ? type.split("-")[1] : type;

  switch (newType) {
    case "onPress":
    case "onChange":
    case "onSelect":
      {
        const { value, row, index } = ftFunctions.getValue(oEvent);

        context.value = value;
        context.row = row;
        context.index = index;
        context.items = oEvent.items;
        context.indices = oEvent.indices;
      }
      break;
    case "onRequest": {
      context.index = oEvent.index;
      context.value = oEvent.value;
      context.row = oEvent.row;
      break;
    }
    case "onConfirm": {
      context.items = oEvent.items;
      break;
    }
    case "onScan": {
      context.value = oEvent;

      break;
    }
    case "onSubmit": {
      const { value } = ftFunctions.getValue(oEvent);
      context.value = value;
      break;
    }
    case "onSort": {
      context.sorter = oEvent.sorter;
      break;
    }
    default: {
      break;
    }
  }

  if (returnContextKeys === true) {
    return Object.keys(context);
  }

  switch (type) {
    case "onChange":
      {
        const { value, index } = context;
        const { componentCtg } = oEvent;

        if (index === undefined) {
          if (!formKey) {
            _dispatch(
              rendererActions._updateComponentData({
                componentCtg,
                componentKey,
                value: context.value
              })
            );
          } else {
            _dispatch(
              rendererActions._updateFormData({
                formKey,
                componentKey,
                value: context.value
              })
            );
          }
        } else {
          _dispatch(
            rendererActions._updateTableData({
              key: tableKey,
              value,
              index,
              colName: componentKey
            })
          );
        }
      }
      break;
    default: {
      break;
    }
  }

  try {
    await executeFunction(getProps(), {
      functionIdentifier: [
        // `dialog(${dialogKey})`,
        `form(${formKey})`,
        `component(${componentKey})`,
        `property(${type})`
      ].join(":"),
      functions: functions || _state.meta.functions,
      uid: functionUid,
      moreContext: context,
      isAsync: true,
      oEvent
    });
  } finally {
    if (autoClose) {
      const oDialog = ftFunctions.getParentDialog(oEvent.oTable);
      // const oParent = test.getMetadata().getName();
      if (oDialog) {
        oDialog.close();
      }
    }
  }
};

export const selectFormId = ({ getProps }) => () => oEvent => {
  const { _dispatch } = getProps();
  const { formKey } = oEvent;

  _dispatch(rendererActions._selectFormId({ formKey }));
};

export const onClose = ({ getProps }) => dialogKey => () => {
  const { _dispatch, _state } = getProps();
  const { data } = _state;

  const isValueHelp = isFalsy(data.dialogs[dialogKey]);

  if (!isValueHelp) {
    _dispatch(
      rendererActions._updateDialogData({
        dialogKey,
        key: "isOpen",
        value: false
      })
    );
  }
};

export const onCancel = ({ getProps }) => dialogKey => () => {
  const { _dispatch } = getProps();

  _dispatch(rendererActions._restoreState(dialogKey));
};
