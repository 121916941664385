import React, { useState } from "react";
import { IFooterBuilderProps } from "@/types/footer";
import ToolbarComponentBuilder from "@/builders/common/ToolbarComponentBuilder";
import clsx from "clsx";
import { formRef } from "@/ref";

const FooterComponentsBuilder = (props: IFooterBuilderProps) => {
  const { footerData, showToggleBtn } = props;

  const [isFormCollapsed, setIsFormCollapsed] = useState(false);

  return (
    <div style={{ display: "flex", gap: "0.5rem" }}>
      {footerData.map(each => {
        return (
          <ToolbarComponentBuilder
            name={each.name}
            key={each.name}
            {...each.field}
            {...each.field.component}
          />
        );
      })}

      {showToggleBtn && (
        <>
          <ToolbarComponentBuilder
            type="Separator"
            value=""
            name="toggle_forms_separator"
          />
          <ToolbarComponentBuilder
            type="Button"
            value=""
            name="toggle_forms"
            properties={{
              className: isFormCollapsed ? "ft-notify-form-is-hidden" : "",
              icon: isFormCollapsed
                ? "sap-icon://expand-group"
                : "sap-icon://collapse-group",
              onClick: () => oEvent => {
                const oldClassName = formRef.current.className;

                if (isFormCollapsed) {
                  const result = oldClassName.replace(
                    "ft-hide-with-effect",
                    ""
                  );
                  formRef.current.className = result;
                  setIsFormCollapsed(now => !now);
                } else {
                  formRef.current.className = clsx(oldClassName, {
                    "ft-hide-with-effect": true
                  });
                  setIsFormCollapsed(now => !now);
                }
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default FooterComponentsBuilder;
