import React from "react";
import { TabContainer, Tab } from "@/components";
import { TabContainerBackgroundDesign } from "@ui5/webcomponents-react";
import clsx from "clsx";
import NodeEditorBuilder from "./Builder";
import { INodeEditorBuilderProps } from "@/types/nodeEditor";
import { NodeEditorContextProvider } from "@/contexts/nodeEditorContext";

interface INodeEditorIteratorProps {
  nodeEditor: INodeEditorBuilderProps[];
}

function NodeEditorIterator(props: INodeEditorIteratorProps) {
  const { nodeEditor } = props;
  const neKeys = nodeEditor.map(ne => ne.name);
  const hasMultiNe = nodeEditor.length > 1;

  const nodeEditorNameList = nodeEditor.map(
    (ne, index) => ne.name || `node-editor-${index}`
  );

  return hasMultiNe ? (
    <TabContainer
      style={{ height: "100%" }}
      fixed={true}
      contentBackgroundDesign={TabContainerBackgroundDesign.Transparent}
      className={clsx("noBottomLine", "ft-height-100-for-codeeditor")}
      onTabSelect={oEvent => {
        const selectedIndex = oEvent.detail.tabIndex;
        const selectHandler = nodeEditor[selectedIndex].onSelect;
        if (selectHandler) {
          const selectedKey = nodeEditorNameList[selectedIndex];
          selectHandler({ tabKey: selectedKey });
        }
      }}
    >
      {neKeys.map((neKey, index) => {
        const neObject = nodeEditor[index];
        const neId = neKey;
        return (
          <Tab
            key={neId}
            text={neObject.title}
            style={{ overflow: "hidden" }}
            selected={neObject.selected}
          >
            <NodeEditorContextProvider>
              <NodeEditorBuilder {...neObject} />
            </NodeEditorContextProvider>
          </Tab>
        );
      })}
    </TabContainer>
  ) : (
    <NodeEditorContextProvider>
      <NodeEditorBuilder {...nodeEditor[0]} />
    </NodeEditorContextProvider>
  );
}

export default NodeEditorIterator;
