import React, { useCallback, useEffect } from "react";
import {
  refSetterOpenDialogIds,
  refSetterValueHelpV2Event
} from "@/contexts/dialogContext";
import { IValueHelpV2Event } from "@/types/dialog";
import { refineEventForDialog } from "@/lib/refineEvent";
import { propsRef } from "@/ref";
import { IValueHelpV2 } from "@/types/component";
import { IDialogData } from "@/types/dialog";
import { useDialogContext } from "@/contexts/dialogContext";
import { IInputProps } from "@/types/component";
import Icon from "@/components/Icon";

export const useDialogHooks = () => {
  const { openDialogIds, valueHelpV2Event, selectedRows } = useDialogContext();
  const fn = propsRef.current.fn;

  const manuallyOpenDialog = useCallback(
    dialogId => {
      if (openDialogIds.includes(dialogId)) return;
      refSetterOpenDialogIds.current(dialogIds => [...dialogIds, dialogId]);
    },
    [openDialogIds, refSetterOpenDialogIds]
  );

  const manuallyCloseDialog = useCallback(
    dialogId => {
      if (openDialogIds.length === 0) return;
      refSetterOpenDialogIds.current(dialogIds =>
        dialogIds.filter(id => id !== dialogId)
      );
    },
    [openDialogIds, refSetterOpenDialogIds]
  );

  const getIsOpen = useCallback(
    ({ isOpenFromProps, dialogId }) => {
      if (isOpenFromProps) return isOpenFromProps;
      return openDialogIds.includes(dialogId);
    },
    [openDialogIds]
  );
  const getDialogEvent = useCallback(
    (props: IDialogData) => {
      const { dialogId } = props;
      const valueHelpEvent = valueHelpV2Event[dialogId];
      if (!!valueHelpEvent)
        return refineEventForDialog(valueHelpEvent, fn, selectedRows);

      return refineEventForDialog(props, fn, selectedRows);
    },
    [valueHelpV2Event, fn, selectedRows]
  );

  const handleCloseDialog = useCallback(
    props => {
      const dialogEvent: IValueHelpV2 = getDialogEvent(props);
      dialogEvent.onBeforeCancel && dialogEvent.onBeforeCancel();
      dialogEvent.onClose && dialogEvent.onClose();
      manuallyCloseDialog(props.dialogId);
    },
    [getDialogEvent, manuallyCloseDialog]
  );

  return {
    registerValueHelpV2Event,
    manuallyOpenDialog,
    manuallyCloseDialog,
    getIsOpen,
    getDialogEvent,
    handleCloseDialog
  };
};

export const useValueHelpV2 = (props: IInputProps) => {
  const { valueHelpV2 } = props.properties;
  const { registerValueHelpV2Event, manuallyOpenDialog } = useDialogHooks();

  useEffect(() => {
    registerValueHelpV2Event(valueHelpV2);
  }, []);

  if (!valueHelpV2) return {};

  const { dialogId } = valueHelpV2;

  return {
    icon: !!valueHelpV2 ? (
      <Icon
        interactive={true}
        name="value-help"
        onClick={() => manuallyOpenDialog(dialogId)}
      />
    ) : null
  };
};

const registerValueHelpV2Event = (valueHelpV2: IValueHelpV2) => {
  if (!valueHelpV2) return;
  const { dialogId, ...functions } = valueHelpV2;

  refSetterValueHelpV2Event.current((currentEvent: IValueHelpV2Event) => {
    return {
      ...currentEvent,
      [dialogId]: functions
    };
  });
};
