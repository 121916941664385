import React from "react";
import { tryit } from "@bsgp/lib-core";
import { constructFormData } from "@bsgp/form-and-table/common";
import FormWrapperBuilder from "@/builders/form/FormWrapperBuilder";
import { ConstructFormDataType } from "@/types/form";
import { IConstructorProps } from "@/types/common";
import { propsRef } from "@/ref";
import FormBuilder from "./FormBuilder";

const FormConstructor = (props: IConstructorProps) => {
  const { data } = props;
  const fn = propsRef.current.fn;

  if (!fn || !data.form) return;

  const wrapForms = tryit(() => data.wrapForms);
  const formDataList: ConstructFormDataType = constructFormData(data.form, fn);

  const doWrapForms = formDataList.length > 1 && wrapForms !== false;

  return doWrapForms ? (
    <FormWrapperBuilder formDataList={formDataList} />
  ) : (
    formDataList.map(formObject => (
      <FormBuilder
        key={formObject.name}
        formObject={formObject}
        title={formObject.title}
      />
    ))
  );
};

export default FormConstructor;
