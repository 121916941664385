import { ButtonDesign } from "@ui5/webcomponents-react";
import { Property } from "csstype";
import { MessageStripDesign } from "@ui5/webcomponents-react";

export const componentNameMap = {
  Input: "sap.m.Input"
};

export const eventPropertyMap = {
  change: "onChange",
  press: "onClick",
  search: "onSearch",
  select: "onSelect"
};

export const dialogEventPropertyMap = {
  ...eventPropertyMap,
  onCancel: "onBeforeCancel"
};

export const hAlignToTextAlignMap: { [key: string]: Property.TextAlign } = {
  Begin: "start",
  Center: "center",
  End: "end",
  Left: "left",
  Right: "right"
};

export const ButtonTypeToDesignMap = {
  Ghost: ButtonDesign.Default,
  Emphasized: ButtonDesign.Emphasized
};

export const messageStripDesignMap = {
  Error: MessageStripDesign.Negative,
  Success: MessageStripDesign.Positive
};
