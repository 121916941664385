import { clone } from "@bsgp/lib-core";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forms: {},
  tables: {},
  dialogs: {},
  hints: {},
  codeeditors: {},
  nodeeditors: {},
  selectedFormId: "",
  bak: {}
};

const rendererSlice = createSlice({
  name: "rendererSlice",
  initialState,
  reducers: {
    _updateDialogData: (state, action) => {
      const { dialogKey, key, value } = action.payload;

      if (!state.dialogs[dialogKey]) {
        state.dialogs[dialogKey] = {};
      }

      state.dialogs[dialogKey][key] = value;

      if (["isOpen", "initialized"].includes(key)) {
        if (value === true) {
          const newState = clone(state);
          delete newState.bak[dialogKey];
          state.bak[dialogKey] = newState;
        }
      }
    },
    _updateFormData: (state, action) => {
      const { formKey, componentKey, value } = action.payload;

      if (!state.forms[formKey]) {
        state.forms[formKey] = {};
      }

      state.forms[formKey][componentKey] = value;
    },
    _overwriteTableData: (state, action) => {
      const { key, value } = action.payload;

      state.tables[key] = clone(value);
    },
    _updateTableData: (state, action) => {
      const { key, value, index, colName, rowData } = action.payload;

      if (rowData === undefined) {
        state.tables[key][index][colName] = value;
      } else {
        state.tables[key][index] = clone(rowData);
      }
    },
    _updateComponentData: (state, action) => {
      const { componentCtg, componentKey, value } = action.payload;

      state[componentCtg][componentKey] = value;
    },
    _selectFormId: (state, action) => {
      const { formKey } = action.payload;

      state.selectedFormId = formKey;
    },
    _updateDataHints: (state, action) => {
      const { hintKey, data } = action.payload;

      state.hints[`${hintKey}`] = data;
    },
    _updateValueFunction: (state, action) => {
      const { type, formKey, componentKey, value } = action.payload;

      state[`${type}`][`${formKey}`][`${componentKey}`] = value;
    },
    _restoreState: (state, action) => {
      const dialogKey = action.payload;

      return state.bak[dialogKey];
    },
    _overwriteState: (state, action) => {
      action.payload(state);
    },
    _initialSpread: (state, action) => {
      const { meta } = action.payload;
      if (meta.forms) {
        meta.forms.forEach(form => {
          if (state.forms[form.key] === undefined) {
            state.forms[form.key] = {};
          }
        });
      }
      if (meta.tables) {
        meta.tables.forEach(table => {
          if (state.tables[table.key] === undefined) {
            state.tables[table.key] = [];
          }
        });
      }
      if (meta.dialogs) {
        meta.dialogs.forEach(dlg => {
          if (state.dialogs[dlg.key] === undefined) {
            state.dialogs[dlg.key] = {};
          }
          if (dlg.forms) {
            dlg.forms.forEach(form => {
              if (state.forms[form.key] === undefined) {
                state.forms[form.key] = {};
              }
            });
          }
          if (dlg.tables) {
            dlg.tables.forEach(table => {
              if (state.tables[table.key] === undefined) {
                state.tables[table.key] = [];
              }
            });
          }
        });
      }
    },
    _initAll: () => {
      return rendererSlice.getInitialState();
    }
  }
});

export const rendererActions = rendererSlice.actions;
export default rendererSlice;
