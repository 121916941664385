import React, { useEffect, memo } from "react";
import { createPortal } from "react-dom";
import { IDialogData } from "@/types/dialog";
import { VBox, Dialog } from "@/components";
import FormConstructor from "@/builders/form/FormConstructor";
import TableConstructor from "@/builders/table/TableConstructor";
import DialogHeaderBuilder from "./DialogHeaderBuilder";
import { useDialogHooks } from "@/hooks/dialog";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import CodeEditorConstructor from "../codeEditor/Constructor";
import { getStyleCodeFromProperties } from "@/lib/utils";
import NodeEditorConstructor from "../nodeEditor/Constructor";

const useStyles = createUseStyles({
  dialog: {
    "&::part(content)": {
      padding: 0
    },
    "& *": {
      boxSizing: "border-box"
    }
  },
  dialogContentPadding: {
    padding: "1rem 0.75rem"
  }
});

const DialogV2Builder = (props: IDialogData) => {
  const {
    properties,
    ftData,
    isOpen: isOpenFromProps,
    fullSize = true,
    disableEsc,
    dialogId
  } = props;
  const { getIsOpen, getDialogEvent } = useDialogHooks();
  const classes = useStyles();

  const isOpenFinal = getIsOpen({ isOpenFromProps, dialogId });

  useEffect(() => {
    if (isOpenFinal === true) {
      const dialogEvent = getDialogEvent(props);

      if (!!dialogEvent.onRequest) {
        dialogEvent.onRequest();
      }
    }
  }, [isOpenFinal]);

  const newFtData = changeMetaDataForDialog(ftData);

  return createPortal(
    <Dialog
      onKeyDown={event => {
        if (event.key === "Escape" && disableEsc) {
          event.stopPropagation();
        }
      }}
      open={isOpenFinal}
      stretch={fullSize}
      header={<DialogHeaderBuilder {...props} />}
      className={clsx("dialog-h-100", classes.dialog)}
      {...properties}
      style={getStyleCodeFromProperties({ properties, props })}
    >
      <VBox
        fitContainer={"codeeditor" in newFtData || "nodeeditor" in newFtData}
        className={clsx({
          [classes.dialogContentPadding]: !("codeeditor" in newFtData)
        })}
      >
        <FormConstructor data={newFtData} />
        <TableConstructor data={newFtData} />
        <CodeEditorConstructor data={newFtData} />
        <NodeEditorConstructor data={newFtData} />
      </VBox>
    </Dialog>,
    document.body
  );
};

function changeMetaDataForDialog(ftData) {
  const newFtData = { ...ftData };

  if (newFtData.table) {
    Object.keys(newFtData.table).forEach(tableKey => {
      newFtData.table[tableKey].component.hideDownloadButton = true;
      newFtData.table[tableKey].component.compactSize = true;
    });
  }

  return {
    ...newFtData,
    wrapForms: false,
    hasFooter: false,
    wrapSingleTable: false
  };
}
export default memo(DialogV2Builder);
