import React from "react";
import {
  MultiComboBox,
  MultiComboBoxDomRef,
  MultiComboBoxItem,
  MultiComboBoxPropTypes,
  Ui5CustomEvent
} from "@ui5/webcomponents-react";
import { isArray } from "@bsgp/lib-core";
import { IComponentProperties } from "@/types/component";

interface IMultiComboBoxProp {
  properties: IComponentProperties &
    MultiComboBoxPropTypes & {
      onChange?: (
        event: Ui5CustomEvent<MultiComboBoxDomRef, never>,
        rowData: object
      ) => void;
    };
  values: string | string[];
  rowData: object;
  options: { value: string; text: string; selected?: boolean }[];
}

function FtMultiComboBox(props: IMultiComboBoxProp) {
  const { rowData = {}, properties = {}, options: rawOptions = [] } = props;

  const values = (isArray(props.values)
    ? props.values
    : [props.values]) as string[];
  const options = rawOptions.map(option => ({
    ...option,
    selected:
      values.find(value => value === option.value) === undefined ? false : true
  }));

  return (
    <MultiComboBox
      {...properties}
      onChange={function(event) {
        return properties.onChange && properties.onChange(event, { rowData });
      }}
    >
      {options.map((opt, index) => {
        return (
          <MultiComboBoxItem
            key={opt.value}
            selected={opt.selected}
            text={opt.text}
          />
        );
      })}
    </MultiComboBox>
  );
}

export default FtMultiComboBox;
