import { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import render5 from "hoc/render5";

import { addError } from "actions/ui5";
import { asyncSignIn, asyncGetData } from "actions/adir";
import {
  newSystemID,
  activatedCapsLock,
  displaySessionInput,
  selectLogInSystem,
  asyncSignOut
} from "actions/user";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    document.addEventListener("keyup", this.keyController);
    this.pressCount = 0;
    this.countTimeoutHandler = undefined;
  }
  componentDidMount = () => {
    // this.checkSystemByQueryString();
    const { dispatch, history } = this.props;
    const queryParams = new URLSearchParams(history.location.search);
    const invalid = queryParams.get("invalid");
    if (invalid) {
      dispatch(addError(invalid));
    }
  };

  componentWillUnmount() {
    document.body.style.backgroundImage = "none";
    document.removeEventListener("keyup", this.keyController);
  }

  checkSystemByQueryString = () => {
    const { currentUser } = this.props;

    const sSystemID = getFromQueryString({ props: this.props })("s");

    const isLoaded = currentUser.system
      ? Object.keys(currentUser.system).length > 0
      : false;

    if (sSystemID && !isLoaded) {
      // getSystemByID({ props: this.props })(sSystemID);
    }
  };

  keyController = event => {
    const { dispatch } = this.props;
    if (
      event.target.id.includes("password") &&
      event.getModifierState &&
      this.props.isActivatedCapsLock !== event.getModifierState("CapsLock")
    ) {
      dispatch(activatedCapsLock(event.getModifierState("CapsLock")));
    }
    if (String(event.code) === "ControlLeft") {
      this.pressCount++;
      if (this.countTimeoutHandler) {
        clearTimeout(this.countTimeoutHandler);
      }
      this.countTimeoutHandler = setTimeout(() => {
        this.pressCount = 0;
      }, 1.5 * 1000);
    } else if (
      String(event.code) === "Escape" &&
      this.props.isSessionKeyVisible
    ) {
      return dispatch(displaySessionInput(false));
    } else this.pressCount = 0;
    if (this.pressCount === 2) {
      return dispatch(displaySessionInput(true));
    }
  };
  render() {
    return true;
  }
}

const getSystemByID = ({ props }) => sid => {
  const { dispatch } = props;
  const [systemID, partnerID] = sid.split("@");

  dispatch(
    asyncGetData(
      { system: { id: systemID, partnerID } },
      {
        afterSucceed: data => {
          const system = data[0];
          dispatch(
            newSystemID({
              systemID: system.id,
              system,
              partnerID: system.partnerID
            })
          );
        }
      }
    )
  );
};

const setSystem = ({ props }) => systemID => {
  const { dispatch } = props;
  dispatch(selectLogInSystem(systemID));
};

const doBackToInitialLogin = ({ props }) => () => {
  const { dispatch } = props;

  dispatch(asyncSignOut());

  window.google.accounts.id.disableAutoSelect();
};

const doSignIn = ({ props }) => (
  getData,
  getPasswordComponent,
  getMfaComponent
) => () => {
  const { dispatch, currentUser, id_token } = props;
  const {
    username,
    password,
    sid,
    sessionKey,
    mfaToken,
    setDefault
  } = getData();

  // Clear the password input
  // const oPassword = getPasswordComponent();
  // oPassword.setValue("");

  if (currentUser.idp === "google" && id_token) {
    // pass
  } else {
    if (!password) {
      dispatch(addError({ message: "The password is required" }));
      return;
    }
  }

  const [systemID, partnerID] = sid.split("@");
  if (!partnerID) {
    console.error(sid);
    dispatch(
      addError({
        message: "Invalid 'System ID'"
      })
    );
    return;
  }

  dispatch(
    asyncSignIn({
      username,
      password,
      id_token,
      idp: currentUser.idp,
      setDefault,
      partnerID,
      systemID,
      sessionKey,
      token: mfaToken
    })
  );

  // if (partnerID === "36pc5h4ur0") {
  //   directoryV2.rest.user.signIn(
  //     { sid: systemID, uid: username, password },
  //     data => {
  //       const userData = data.result;
  //       resSignIn({
  //         jsonResponse: {
  //           body: {
  //             user: {
  //               ...userData,
  //               id: userData.uid,
  //               partnerID: userData.pid,
  //               systemID: userData.sid
  //             },
  //             requireMfa: false
  //           }
  //         }
  //       })(dispatch);
  //     }
  //   );
  // } else {
  //   dispatch(
  //     asyncSignIn({
  //       username,
  //       password,
  //       partnerID,
  //       systemID,
  //       sessionKey,
  //       pid,
  //       token: mfaToken
  //     })
  //   );
  // }
};

const getFromQueryString = ({ props }) => key => {
  const { history } = props;
  const { location } = history;
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get(key);
};

const sameWithQueryString = ({ props }) => (key, value) => {
  const resultValue = getFromQueryString({ props })(key);

  return value && Boolean(value === resultValue);
};

function mapStateToProps(state) {
  return {
    render5Version: "20190808",
    pid: state.user.currentUser.pid,
    currentUser: state.user.currentUser,
    isSessionKeyVisible: state.user.isSessionKeyVisible,
    isActivatedCapsLock: state.user.isActivatedCapsLock,
    selectedLogInSystem: state.user.selectedLogInSystem,
    requireMfa: state.user.requireMfa,
    alterSystems: state.user.alterSystems,
    id_token: state.user.id_token
  };
}

export default compose(
  connect(
    mapStateToProps // mapDispatchToProps
  ),
  withRouter,
  render5(import("components/login.ui5"), {
    doSignIn,
    doBackToInitialLogin,
    sameWithQueryString,
    getFromQueryString,
    getSystemByID,
    setSystem
  })
)(LoginPage);
