export const MODES = {
  CREATE: {},
  READ: {},
  UPDATE: {},
  DELETE: {}
};

export const extractAttributes = (
  forms,
  tables,
  codeeditors = [],
  nodeeditors = []
) => {
  const attrForms =
    forms &&
    forms
      .filter(form => form.ignore !== true)
      .reduce((acc, form) => {
        const row = {
          id: ["forms", form.key].join("."),
          attributes: []
        };
        if (form.containers) {
          form.containers
            .filter(cont => cont.ignore !== true)
            .forEach(cont => {
              if (cont.fields) {
                cont.fields
                  .filter(fld => fld.ignore !== true)
                  .forEach(fld => {
                    if (fld.components) {
                      fld.components
                        .filter(component => component.ignore !== true)
                        .forEach(comp => {
                          row.attributes.push(comp.key || fld.key);
                        });
                    }
                  });
              }
            });
        }
        row.attributes = row.attributes.join(", ");
        acc.push(row);
        return acc;
      }, []);
  const attrTables =
    tables &&
    tables
      .filter(table => table.ignore !== true)
      .map(table => {
        const row = {
          id: ["tables", table.key].join("."),
          attributes: []
        };
        if (table.columns) {
          table.columns
            .filter(col => col.ignore !== true)
            .forEach(col => {
              if (col.components) {
                const colComps = col.components.filter(
                  comp => comp.ignore !== true
                );
                if (colComps.length <= 1) {
                  row.attributes.push(col.key);
                } else {
                  colComps
                    .filter(component => component.ignore !== true)
                    .forEach(comp => {
                      row.attributes.push(comp.key);
                    });
                }
              }
            });
        }
        row.attributes = row.attributes.join(", ");
        return row;
      });

  const attrCodeEditors = codeeditors
    .filter(ce => ce.ignore !== true)
    .map(codeeditor => {
      const row = {
        id: ["codeeditors", codeeditor.key].join("."),
        attributes: ""
      };
      return row;
    });

  const attrNodeEditors = nodeeditors
    .filter(ne => ne.ignore !== true)
    .map(nodeeditor => {
      const row = {
        id: ["nodeeditors", nodeeditor.key].join("."),
        attributes: [
          "id",
          "begin",
          "end",
          "{nodeId} : {id, position, name, next}"
        ].join(", ")
      };
      return row;
    });

  return [...attrForms, ...attrTables, ...attrCodeEditors, ...attrNodeEditors];
};

export const getValueByPath = (data, path) => {
  if (path === undefined) {
    return undefined;
  }

  const pathArray = path.split(".");
  if (pathArray[0] === "state") {
    pathArray.shift();
  }
  try {
    return pathArray.reduce((acc, curr) => acc[curr], data);
  } catch (ex) {
    return undefined;
  }
};

export const splitSpecialKeyIfExists = key => {
  if (!key.startsWith("properties-") && !key.startsWith("valueHelpV2-")) {
    return null;
  }

  const keyPrefix = key.startsWith("properties-")
    ? "properties"
    : "valueHelpV2";
  const propsKey = keyPrefix;
  const propsName = key.replace(`${keyPrefix}-`, "");
  return [propsKey, propsName];
};
