import React from "react";
import NodeEditor from "@/components/nodeEditor/NodeEditor";
import { VBox } from "@/components";
import { INodeEditorBuilderProps } from "@/types/nodeEditor";
import { useInitializeNodeEditor } from "@/hooks/nodeEditor";
import ToolbarBuilder from "./ToolbarBuilder";
import { useRefineEventForNodeEditor } from "@/hooks/refineProps";

function NodeEditorBuilder(props: INodeEditorBuilderProps) {
  useInitializeNodeEditor(props);

  const refinedProps = useRefineEventForNodeEditor(
    {
      ...props
    },
    props
  );

  return (
    <VBox style={{ flex: 1, height: "100%" }}>
      <ToolbarBuilder {...refinedProps} />
      <NodeEditor style={{ width: "100%", height: "100%" }} {...refinedProps} />
    </VBox>
  );
}

export default NodeEditorBuilder;
