import React from "react";
import { IHistoryLink } from "@/types/common";
import { Breadcrumbs } from "@/components";
import path from "path";
import { History } from "history";

interface IHistoryBuilder {
  historyLinks: IHistoryLink[];
  history: History;
  title?: string;
}

const HistoryBuilder = (props: IHistoryBuilder) => {
  const { historyLinks, history, title } = props;

  const lastIndex = historyLinks.length - 1;
  const presentLink = history.location.pathname;

  const links = historyLinks
    .filter((each, idx) => {
      let href = each.url || each.href || "";
      href = path.resolve(presentLink, href);

      return !(
        lastIndex === idx && presentLink.toLowerCase() === href.toLowerCase()
      );
    })
    .map((hLink, idx) => {
      let href = hLink.url || hLink.href || "";
      if (href) {
        href = path.resolve(presentLink, href);
        href += history.location.search;
      }

      return {
        text: hLink.text,
        href: href
      };
    })
    .concat([{ text: title, href: presentLink }]);

  return (
    <Breadcrumbs
      items={links}
      onItemClick={event => {
        event.preventDefault();
        history.push(event.detail.item.href);
      }}
    />
  );
};

export default HistoryBuilder;
