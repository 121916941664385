import React, { useState } from "react";
import { Button } from "@ui5/webcomponents-react";
import { BarcodeScannerDialog } from "@ui5/webcomponents-react";
import { propsRef } from "@/ref";
import { IBarcodeButtonProps } from "@/types/component";

function BarcodeScannerButton(props: IBarcodeButtonProps) {
  const {
    properties: { text = "", ...restProperties },
    style,
    onError,
    onScan
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { fn } = propsRef.current;

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        design="Default"
        {...restProperties}
        onClick={openDialog}
        style={{
          width: "100%",
          ...style
        }}
      >
        {text as string}
      </Button>
      <BarcodeScannerDialog
        open={isOpen}
        onScanSuccess={event => {
          const barcode = event.detail.text;
          onScan(fn)(barcode);
          closeDialog();
        }}
        onScanError={event => {
          const errorMsg = event.detail.message;
          onError(fn)(errorMsg);
          closeDialog();
        }}
      />
    </>
  );
}

export default BarcodeScannerButton;
