import { messageBox } from "ui5-lib-rc";
import { functions } from "@bsgp/form-and-table";
import slice, { metaAttributes, initialState } from "./slice";
import * as constants from "./forms";
import { metaIndexMap, extractFunctions } from "./modules";
import { executeFunction, getValue, getItems } from "../renderer/modules";
import { getMetaFuncContent } from "../renderer/controller";
import { apiHub, directory } from "@bsgp/lib-api";
import { tryit, clone } from "@bsgp/lib-core";
import { addError } from "actions/ui5";

const builderActions = slice.actions;

const endPoint = "/g/lc_ui5";

export const toggleDeployDialog = ({ getProps }) => isOpen => async oEvent => {
  const { _dispatch, dispatch } = getProps();

  if (isOpen === true) {
    const { row } = functions.getValue(oEvent);
    await dispatch(
      directory.getPartners({
        afterSucceed: data => {
          _dispatch(builderActions._toggleDeployDialog({ isOpen }));
          _dispatch(
            builderActions._updateDeployValue({
              partners: data,
              version: row.lstvsn,
              description: row.description,
              presys: row.presys || []
            })
          );
        }
      })
    );
  } else {
    _dispatch(builderActions._toggleDeployDialog({ isOpen }));
  }
};

export const updateDeployValue = ({ getProps }) => key => async oEvent => {
  const { _dispatch, dispatch } = getProps();

  const { value } = functions.getValue(oEvent);

  if (key === "partner") {
    await dispatch(
      directory.getSystems(
        {
          afterSucceed: data => {
            _dispatch(builderActions._updateDeployValue({ systems: data }));
          }
        },
        { partnerID: value }
      )
    );
  }
  _dispatch(builderActions._updateDeployValue({ [key]: value }));
};

export const toggleCreateVersionDialog = ({
  getProps
}) => isOpen => async oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._toggleCreateVersionDialog({ isOpen }));
};

export const toggleMoveDialog = ({ getProps }) => (
  isOpen,
  options
) => async oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._toggleMoveDialog({ isOpen, options }));
};

export const toggleExportDialog = ({ getProps }) => (
  isOpen,
  options
) => async oEvent => {
  const { _dispatch } = getProps();
  _dispatch(builderActions._toggleExportDialog({ isOpen, options }));
};

export const updateVersionValue = ({ getProps }) => key => async oEvent => {
  const { _dispatch } = getProps();

  const { value } = functions.getValue(oEvent);

  _dispatch(builderActions._updateVersionValue({ [key]: value }));
};

export const updateMoveValue = ({ getProps }) => key => async oEvent => {
  const { _dispatch } = getProps();

  const { value } = functions.getValue(oEvent);

  _dispatch(builderActions._updateMoveValue({ [key]: value }));
};

export const updateExportValue = ({ getProps }) => key => async oEvent => {
  const { _dispatch } = getProps();
  const { value } = functions.getValue(oEvent);
  _dispatch(builderActions._updateExportValue({ [key]: value }));
};

export const confirmMove = ({ getProps }) => async () => {
  const { dispatch, _dispatch, _state } = getProps();

  if (_state.meta.extra.dialog.target.keys[0] === "dialogs") {
    const { index } = _state.meta.extra.move;
    // const { type } = _state.meta.extra.dialog.target;
    _dispatch(builderActions._moveFromDialog({ index }));
    _dispatch(builderActions._closeDialog());
  } else {
    const { dialogKey } = _state.meta.extra.move;
    if (!dialogKey) {
      dispatch(addError("이동할 타겟 Dialog의 Key를 선택해주세요."));
      return false;
    }

    _dispatch(builderActions._moveToDialog({ dialogKey }));
  }

  _dispatch(builderActions._toggleMoveDialog({ isOpen: false }));
};

export const confirmExport = ({ getProps }) => async () => {
  const { _dispatch, _state, dispatch } = getProps();

  const { globalFunction } = _state.meta.extra.export;
  const { rootKey_s, indexValue } = _state.meta.extra.dialog.target;

  const newMeta = clone(initialState);
  const targetObj = _state.meta[rootKey_s][indexValue];
  const funcValues = [];
  extractFunctions(targetObj, funcValues);

  const functions = funcValues.reduce((acc, value) => {
    acc[value] = _state.meta.functions[value];
    return acc;
  }, {});

  newMeta[rootKey_s].push(targetObj);
  newMeta.functions = { ...newMeta.functions, ...functions };
  newMeta.description = `export from ${_state.meta.id}`;

  if (globalFunction) {
    newMeta.functions.global = _state.meta.functions.global;
  }

  await apiHub.post(endPoint, newMeta, {
    afterSucceed: data => {
      dispatch(addError(`export 성공, meta ID - ${data.id}`));
    }
  });

  _dispatch(builderActions._exportMeta({ funcValues }));
  _dispatch(builderActions._toggleExportDialog({ isOpen: false }));
};

export const confirmCreateVersion = ({ getProps }) => async () => {
  const { dispatch, _dispatch, _state } = getProps();

  if (!_state.meta.extra.version.description) {
    dispatch(addError("버전 설명을 입력해주세요."));
    return false;
  }

  await apiHub.put(
    endPoint,
    {
      createVersion: {
        metaId: _state.meta.id,
        description: _state.meta.extra.version.description
      }
    },
    {
      afterSucceed: data => {
        if (data.id === _state.meta.id) {
          dispatch(addError({ type: "S", message: "버전이 생성되었습니다" }));
          _dispatch(
            builderActions._toggleCreateVersionDialog({ isOpen: false })
          );
          _dispatch(builderActions._getMetaData(data));
        }
      }
    }
  );
};

export const addSystemToSp = ({ getProps }) => async oEvent => {
  const { _dispatch, _state } = getProps();
  const { partner, system, presys } = _state.meta.extra.deploy;

  if (partner && system) {
    _dispatch(
      builderActions._updateDeployValue({
        presys: [...presys, [system, partner].join("@")]
      })
    );
  }
};

export const deployVersion = ({ getProps }) => async oEvent => {
  const { dispatch, _dispatch, _state } = getProps();
  const { row } = functions.getValue(oEvent);

  await apiHub.put(
    endPoint,
    {
      deployVersion: {
        metaId: _state.meta.id,
        versionId: row.lstvsn
      }
    },
    {
      afterSucceed: data => {
        if (data.id === _state.meta.id) {
          dispatch(
            addError({
              type: "S",
              message: `버전 ${row.lstvsn} 배포하였습니다`
            })
          );
          _dispatch(builderActions._getMetaData(data));
        }
      }
    }
  );
};

export const confirmDeployVersion = ({ getProps }) => async oEvent => {
  const { dispatch, _dispatch, _state } = getProps();
  const { version, presys } = _state.meta.extra.deploy;

  await apiHub.put(
    endPoint,
    {
      preDeployVersion: {
        metaId: _state.meta.id,
        versionId: version,
        presys
      }
    },
    {
      afterSucceed: data => {
        if (data.id === _state.meta.id) {
          dispatch(
            addError({
              type: "S",
              message: `버전 ${version} 배포하였습니다`
            })
          );
          _dispatch(builderActions._getMetaData(data));
          _dispatch(builderActions._toggleDeployDialog({ isOpen: false }));
        }
      }
    }
  );
};

export const postMetaData = ({ getProps }) => ({
  isCreate
}) => async oEvent => {
  const { _dispatch, _state, history, path } = getProps();

  let errorCode;
  await apiHub.post(
    endPoint,
    metaAttributes.reduce((acc, attr) => {
      acc[attr] = _state.meta[attr];
      return acc;
    }, {}),
    {
      afterSucceed: data => {
        if (isCreate) {
          history.replace(`${path}/builder/${data.id}`);
        }
        _dispatch(builderActions._getMetaData(data));

        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "errorMessage",
            value: ""
          })
        );
      },
      afterFailed: err => {
        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "errorMessage",
            value: err.body.errorMessage
          })
        );
        errorCode = "X";
      }
    }
  );
  return { errorCode };
};

export const getMetaData = ({ getProps }) => ({ id, isCreate }) => () => {
  const { _dispatch } = getProps();

  apiHub.get(
    endPoint,
    { id },
    {
      afterSucceed: res => {
        _dispatch(builderActions._getMetaData(res));
      },
      afterFailed: data => {
        moveToList({ getProps })();
      }
    }
  );
};

export const onCopyToDev = ({ getProps }) => oEvent => {
  const { _state, _dispatch } = getProps();
  apiHub.put(
    endPoint,
    {
      copyMetaToDev: {
        id: _state.meta.id
      }
    },
    {
      afterSucceed: data => {
        _dispatch(
          builderActions._updateMetaProperty({
            key: "message",
            value: data.message,
            extra: true
          })
        );
      }
    }
  );
};

export const moveToPreviewFromForm = ({ getProps }) => oEvent => {
  const { history, path, _state, dispatch } = getProps();
  const { selectedPath } = _state.meta.extra;

  if (!selectedPath) {
    return dispatch(addError("Path를 선택해주세요"));
  }

  // postMetaData({ getProps })({})().then(data => {
  //   if (data.errorCode !== "X") {
  //   }
  // });
  history.push(path + `/preview/${selectedPath.replace("/", "")}`);
};

export const moveToPreview = ({ getProps }) => oEvent => {
  const { history, path, _state } = getProps();
  const { index } = functions.getValue(oEvent);
  const selectedPath = _state.meta.paths[index].origin;

  history.push(path + `/preview/${selectedPath.replace("/", "")}`);
};

export const moveToList = ({ getProps }) => oEvent => {
  const { history, path } = getProps();

  history.push(path + `/list`);
};

export const updateMetaProperty = ({ getProps }) => (key, extra) => oEvent => {
  const { _dispatch } = getProps();
  const { value } = functions.getValue(oEvent);

  _dispatch(builderActions._updateMetaProperty({ key, value, extra }));
};

export const onUpdateTableCell = ({ getProps }) => (
  tabName,
  cellName
) => oEvent => {
  const { _dispatch } = getProps();

  const { index, value } = functions.getValue(oEvent);
  _dispatch(
    builderActions._updateTableCell({ index, value, cellName, tabName })
  );
};

export const onUpdatePath = ({ getProps }) => cellName => oEvent => {
  const { _dispatch, _state } = getProps();
  const { meta } = _state;

  const { index, value } = functions.getValue(oEvent);

  const prevPath = tryit(() => meta.paths[index]) || {};

  const payload = { id: prevPath.skid, metaId: meta.id };
  if (cellName === "origin") {
    payload.path = value;
  } else {
    payload.path = prevPath.origin;
    payload[cellName] = value;
  }

  _dispatch(
    builderActions._updateTableCell({
      index,
      value,
      cellName,
      tabName: "paths"
    })
  );

  apiHub.put(
    endPoint,
    {
      updatePath: payload
    },
    {
      afterSucceed: data => {
        _dispatch(
          builderActions._updateTableRow({
            index,
            row: data,
            tabName: "paths"
          })
        );
        if (cellName === "origin") {
          _dispatch(
            builderActions._updateMetaProperty({
              key: "selectedPath",
              value,
              extra: true
            })
          );
        }
      }
    }
  );
};

export const onAddPath = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._addPath());
};

export const addExtraObject = ({ getProps }) => params => oEvent => {
  const { indecies, keys, subKey, sub2Index } = params || {};
  const { _dispatch } = getProps();
  const { row } = functions.getValue(oEvent);

  if (params.keys) {
    _dispatch(
      builderActions._addExtraObject({
        keys,
        indecies,
        obj: constants[subKey]
      })
    );
  } else {
    _dispatch(
      builderActions._addExtraObject({
        keys: [...row.keys, row[`sub2Key_s_${sub2Index}`]],
        indecies: row.indecies,
        obj: constants[row[`sub2Key_${sub2Index}`]],
        dummy: "from second level"
      })
    );
  }
};

const checkDialogInvalid = state => {
  const invalid = state.extra.dialog?.invalid || {};

  const isInvalid = Object.values(invalid).includes(true);
  return isInvalid;
};

export const confirmDialogUpdate = ({ getProps }) => oEvent => {
  const { _dispatch, _state } = getProps();
  const invalid = checkDialogInvalid(_state.meta);
  if (invalid) return false;
  _dispatch(builderActions._closeDialog());
  postMetaData({ getProps })({})();
};

export const cancelDialogUpdate = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._undoDialogUpdate());
  _dispatch(builderActions._closeDialog());
};

export const cancelCodeEditorUpdate = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._cancelCodeEditorUpdate());
};

export const updateRequiredKey = ({ getProps }) => requiredKey => oEvent => {
  const { _dispatch } = getProps();
  const { value } = functions.getValue(oEvent);

  _dispatch(builderActions._updateRequiredKey({ requiredKey, value }));
  _dispatch(builderActions._updateDialogInvalid({ requiredKey, value }));
};
export const onUpdateContainerIndex = ({ getProps }) => ({
  maxIndex,
  currentIndex
}) => oEvent => {
  const { _dispatch, dispatch } = getProps();
  const { value } = functions.getValue(oEvent);
  const newIndex = parseInt(value);
  if (newIndex > maxIndex) {
    return dispatch(addError(`최대 Index값은 ${maxIndex}입니다`));
  }

  _dispatch(builderActions._moveObject({ newIndex }));
  _dispatch(builderActions._closeDialog());
};

export const onMoveObjectToAnotherParent = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();
  const { value } = functions.getValue(oEvent);

  messageBox.yesOrNo("이동하겠습니까?", {
    yes: () => {
      _dispatch(
        builderActions._moveObjectToAnotherParent({
          newParentKey: value
        })
      );
      _dispatch(builderActions._closeDialog());
    }
  });
};

export const updateKeyColumn = ({ getProps }) => oEvent => {
  const { _dispatch, dispatch } = getProps();
  const { row, value } = functions.getValue(oEvent);

  const oldKey = row.key;
  const newKey = value;
  const invalidKeys = ["key", "ignore", "binding", "index"];

  if (invalidKeys.includes(newKey)) {
    return dispatch(
      addError({ message: `${newKey}를 option으로 선택할 수 없습니다.` })
    );
  }

  _dispatch(
    builderActions._updateKeyColumn({
      oldKey,
      newKey
    })
  );
  if (newKey.startsWith("on") && row.value === "") {
    _dispatch(
      builderActions._updateValueType({
        newType: "Function",
        row: {
          ...row,
          key: newKey
        }
      })
    );
  }
};

export const updateIgnoreOption = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  const { value, row } = functions.getValue(oEvent);

  _dispatch(
    builderActions._updateIgnoreOption({
      value,
      key: row.key
    })
  );
};

export const selectMeta = ({ getProps }) => ({ items }) => {
  const { _dispatch, dispatch } = getProps();

  if (items.length === 0) {
    return dispatch(addError({ message: "선택한 Meta가 없습니다." }));
  } else {
    functions.confirmWithDialog(
      "Meta를 선택하면 현재 Dialog의 하위 요소들이 모두 제거됩니다. 진행하시겠습니까?",
      () => {
        _dispatch(
          builderActions._updateRequiredKey({
            requiredKey: "metaId",
            value: items[0].id
          })
        );
      }
    );
  }
};

export const queryAllMeta = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();
  apiHub.get(
    endPoint,
    { id: "*" },
    {
      afterSucceed: res => {
        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "metaList",
            value: res.list
          })
        );
      }
    }
  );
};

export const updateValueColumn = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  const nowKey = functions.getValue(oEvent).row.key;
  const newValue = functions.getValue(oEvent).value;

  _dispatch(builderActions._updateValueColumn({ nowKey, newValue }));
};

export const openEditDialog2 = ({ getProps }) => ({
  rootKey_s,
  indexValue,
  indexKey
}) => oEvent => {
  const { _dispatch } = getProps();

  _dispatch(
    builderActions._openEditDialog2({
      type: rootKey_s,
      keys: [rootKey_s],
      [indexKey]: indexValue
    })
  );
};

export const openSubDialog = ({ getProps }) => ({
  rootKey_s,
  rootIdx
}) => oEvent => {
  const { _dispatch } = getProps();
  const { subKey_s, keys, indecies } = functions.getValue(oEvent).row;

  _dispatch(
    builderActions._openEditDialog2(
      keys.reduce(
        (acc, key, idx) => {
          acc[metaIndexMap[key]] = indecies[idx];
          return acc;
        },
        {
          type: subKey_s,
          keys
        }
      )
    )
  );
};

export const addProperties = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();

  _dispatch(builderActions._addProperties());
};

export const updateValueType = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();
  const { value, row } = functions.getValue(oEvent);

  _dispatch(
    builderActions._updateValueType({
      newType: value,
      row
    })
  );
};

export const openCodeEditorDialog = ({
  getProps
}) => presetValue => async oEvent => {
  const { _dispatch } = getProps();
  const { row } = functions.getValue(oEvent);
  const { value, key } = row || { value: presetValue, key: "" };

  if (presetValue === "global") {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: false,
        contextKeys: []
      })
    );
    return;
  }

  const contextKeys = executeFunction(getProps(), { returnContextKeys: true });
  const moreContextKeys = await getMetaFuncContent({
    getProps,
    returnContextKeys: true
  })({ type: key })(oEvent);
  const more2ContextKeys =
    key === "value"
      ? getValue([], {
          props: getProps(),
          returnContextKeys: true
        })
      : [];
  const more3ContextKeys =
    key === "items"
      ? getItems({}, { props: getProps(), returnContextKeys: true })
      : [];

  const finalContextKeys = [
    ...contextKeys,
    ...moreContextKeys,
    ...more2ContextKeys,
    ...more3ContextKeys
  ];

  if (
    [
      "valueHelpV2-onConfirm",
      "valueHelpV2-onRequest",
      "onChange",
      "onSelect",
      "onPress",
      "onConfirm",
      "onRequest",
      "onScan",
      "onError",
      "onSubmit",
      "onSort",
      "onNodeDoubleClick"
    ].includes(key) ||
    row === undefined
  ) {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: true,
        contextKeys: finalContextKeys
      })
    );
  } else {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: false,
        contextKeys: finalContextKeys
      })
    );
  }
};

export const updateOptionsFunc = ({ getProps }) => oEvent => {
  const { _dispatch } = getProps();
  const { value } = functions.getValue(oEvent);

  _dispatch(builderActions._updateOptionsFunc({ newValue: value }));
};

export const confirmCodeEditorUpdate = ({ getProps }) => async oEvent => {
  const { _dispatch, _state, dispatch } = getProps();

  const sourcecode =
    _state.meta.functions[_state.meta.extra.dialog.target.functionUid].content;

  const result = await apiHub.lintSourceCode({
    code: sourcecode
  });

  const { output, resultText } = result.data.cbData;

  if (output) {
    _dispatch(
      builderActions._updateOptionsFunc({ newValue: output, resultText })
    );
  }

  if (resultText) {
    dispatch(
      addError({
        message: "Lint Errors occurred",
        description: resultText
      })
    );
  } else {
    messageBox.yesOrNo("저장하겠습니까?", {
      yes: () => {
        postMetaData({ getProps })({})();
        _dispatch(builderActions._confirmCodeorUpdate());
      }
    });
  }
  return false;
};
