import React, { ReactNode } from "react";
import { TabContainer, Tab } from "@/components";
import FormBuilder from "./FormBuilder";
import { IFormBuildIteratorProps } from "@/types/form";
import clsx from "clsx";
import { TabContainerBackgroundDesign } from "@ui5/webcomponents-react";

const FormWrapperBuilder = (props: IFormBuildIteratorProps): ReactNode => {
  const { formDataList } = props;
  const formNameList = formDataList.map((formObject, index) => {
    return formObject.namedForm ? formObject.name : `form-${index}`;
  });
  return (
    <TabContainer
      fixed={true}
      onTabSelect={oEvent => {
        const selectedIndex = oEvent.detail.tabIndex;
        const selectHandler = formDataList[selectedIndex].onSelect;
        if (selectHandler) {
          const selectedKey = formDataList[selectedIndex].name;
          selectHandler({ formKey: selectedKey });
        }
      }}
      data-ui5-compact-size
      className={clsx("ft-transition-for-hide", "noBottomLine")}
      contentBackgroundDesign={TabContainerBackgroundDesign.Transparent}
      style={{ flex: 0 }}
    >
      {formDataList.map((formObject, index) => {
        const formName = formNameList[index];
        const { onSelect, ...rest } = formObject;
        return (
          <Tab
            key={formName}
            text={formObject.title}
            style={{ overflow: "hidden" }}
          >
            <FormBuilder formObject={rest} title={""} />
          </Tab>
        );
      })}
    </TabContainer>
  );
};

export default FormWrapperBuilder;
