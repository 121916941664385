import {
  NEW_PID,
  NEW_USER,
  SET_REQUIRE_MFA,
  SELECT_LOGIN_SYSTEM,
  ACTIVATED_CAPS_LOCK,
  DISPLAY_SESSION_INPUT,
  SIGN_IN,
  SIGN_OUT,
  UPDATE_EXPIRES_AT,
  GET_CURRENT_USER,
  CLOSE_ROOT_NOTICE,
  CANCEL_CHANGING_INITIAL_PASSWORD,
  CHANGE_INITIAL_PASSWORD,
  STORE_PATHS_FOR_CURRENT_USER,
  NEW_SYSTEM_ID,
  UPDATE_EMPLOYEE_INFO,
  SET_ALTER_SYSTEMS,
  GET_IDP_REDIRECTED,
  SET_CURRENT_THEME
} from "actions/user";
import { tryit } from "@bsgp/lib-core";
import produce from "immer";

const ITEM_KEY_CURRENT_USER = "currentUser";

const initialState = {
  list: [],
  currentUser: {
    username: null,
    password: "",
    lang: null,
    supportLanguages: ["en", "ko"],
    defaultLanguage: "en",
    is_authenticated: false,
    pid: null,
    history: {
      systems: []
    },
    theme: "sap_fiori_3",
    expiresAt: ""
  },
  requireMfa: false,
  isSessionKeyVisible: false,
  isActivatedCapsLock: false,
  selectedLogInSystem: "",
  id_token: "",
  alterSystems: [],
  idpRedirected: false
};

function setFullSubPaths(subPaths, subPathCollection) {
  if (!subPaths) {
    return;
  }
  return subPaths.map(subP => {
    subP.subPaths = setFullSubPaths(subP.subPaths, subPathCollection);
    if (subP.id) {
      const matchedSubPath = subPathCollection.find(
        path => path.id === subP.id
      );
      if (matchedSubPath) {
        console.debug("found matched subPath");
        subP = { ...matchedSubPath, subPaths: subP.subPaths };
      }
    }
    return subP;
  });
}

function flatPaths(paths) {
  return paths.reduce((accList, path) => {
    accList.push(path);
    if (path.subPaths) {
      if (path.subPaths.length > 0) {
        return accList.concat(flatPaths(path.subPaths));
      }
    }
    return accList;
  }, []);
}

export default function reducer(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case STORE_PATHS_FOR_CURRENT_USER:
        draft.currentUser.pathLoaded = true;
        draft.currentUser.paths = undefined;
        draft.currentUser.flattedPaths = undefined;
        draft.currentUser.roles = undefined;

        if (action.paths === undefined) {
          return;
        }
        draft.currentUser.paths = action.paths.map(path => {
          return {
            ...path,
            subPaths: setFullSubPaths(path.subPaths, action.subPathCollection)
          };
        });
        draft.currentUser.flattedPaths = flatPaths(draft.currentUser.paths);
        if (action.roles) {
          draft.currentUser.roles = action.roles;
        }
        break;
      case UPDATE_EMPLOYEE_INFO:
        const identification = action.identity.Identification.find(
          iden => iden.PartyIdentifierTypeCode === "HCM001"
        );
        const employee = {
          id: identification && identification.BusinessPartnerID,
          formattedName: action.identity.BusinessPartnerFormattedName,
          businessPartner: {
            id: action.identity.InternalID,
            nickName: action.identity.NickName,
            givenName: action.identity.GivenName,
            familyName: action.identity.FamilyName
          },
          businessUser: {
            id: action.identity.ID
          }
        };
        draft.currentUser.employee = employee;

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case NEW_SYSTEM_ID:
        draft.currentUser.systemID = action.systemID;
        draft.currentUser.partnerID = action.partnerID;
        draft.currentUser.system = action.system;
        if (action.systemID && action.partnerID) {
          draft.selectedLogInSystem = [action.systemID, action.partnerID].join(
            "@"
          );
        }
        break;
      case NEW_PID:
        draft.currentUser.pid = action.pid;
        draft.currentUser.is_authenticated = false;
        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case NEW_USER:
        draft.currentUser.username = action.username;
        draft.currentUser.id = action.username;
        draft.currentUser.systemID =
          action.systemID || draft.currentUser.systemID;
        draft.currentUser.partnerID =
          action.partnerID || draft.currentUser.partnerID;
        draft.currentUser.is_authenticated = false;

        if (draft.currentUser.system) {
          if (draft.currentUser.system.id !== draft.currentUser.systemID) {
            draft.currentUser.system = null;
          }
        }

        if (draft.currentUser.partner) {
          if (draft.currentUser.partner.id !== draft.currentUser.partnerID) {
            draft.currentUser.partner = null;
          }
        }

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case SELECT_LOGIN_SYSTEM:
        draft.selectedLogInSystem = action.systemID;
        draft.requireMfa = false;
        if (draft.selectedLogInSystem) {
          [
            draft.currentUser.systemID,
            draft.currentUser.partnerID
          ] = draft.selectedLogInSystem.split("@");
        }
        break;
      case ACTIVATED_CAPS_LOCK:
        draft.isActivatedCapsLock = action.isActivated;
        break;
      case DISPLAY_SESSION_INPUT:
        draft.isSessionKeyVisible = action.visible;
        break;
      case CANCEL_CHANGING_INITIAL_PASSWORD:
        draft.currentUser.passwordIsReset = false;
        break;
      case CHANGE_INITIAL_PASSWORD:
        draft.currentUser.passwordIsReset = false;
        draft.currentUser.password = action.newPassword;

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case SET_REQUIRE_MFA:
        draft.requireMfa = true;
        break;
      case SIGN_IN:
        draft.requireMfa = false;
        draft.currentUser.is_authenticated = true;
        draft.currentUser = {
          ...draft.currentUser,
          ...action.user,
          username: action.user.id
        };
        const pid = tryit(() =>
          action.user.system.url
            .replace(/^https:\/\//, "")
            .replace(/\.sapbydesign\.com\/{0,1}.{0,}$/, "")
            .replace(/\.businessbydesign\.cloud\.sap\/{0,1}.{0,}$/, "")
        );
        draft.currentUser.pid = pid;

        // Will be reloaded by getPathsForCurrentUser
        delete draft.currentUser.paths;
        // Will be reloaded by getPathsForCurrentUser
        delete draft.currentUser.roles;
        // Will be reloaded by CustomOdata(Identity)
        delete draft.currentUser.employee;

        // delete draft.currentUser.password;

        if (!draft.currentUser.history) {
          draft.currentUser.history = {};
        }

        if (!draft.currentUser.history.systems) {
          draft.currentUser.history.systems = [];
        }

        const currentPartnerID = draft.currentUser.partnerID;
        const currentSystemID = draft.currentUser.systemID;
        const matchedSystem = draft.currentUser.history.systems.find(
          sys =>
            sys.partnerID === currentPartnerID &&
            sys.systemID === currentSystemID
        );
        if (!matchedSystem) {
          draft.currentUser.history.systems.push({
            partnerID: currentPartnerID,
            partnerName: tryit(() => draft.currentUser.partner.name),
            systemID: currentSystemID,
            systemName: tryit(() => draft.currentUser.system.name),
            systemLogo: tryit(() => draft.currentUser.system.logo),
            userID: draft.currentUser.id
          });
        } else {
          matchedSystem.systemName = draft.currentUser.system.name;
          matchedSystem.systemLogo = draft.currentUser.system.logo;
          matchedSystem.partnerName = draft.currentUser.partner.name;
          matchedSystem.userID = draft.currentUser.id;
        }

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case UPDATE_EXPIRES_AT:
        draft.currentUser.expiresAt = action.expiresAt;
        break;
      case SIGN_OUT:
        draft.currentUser.password = null;
        draft.currentUser.is_authenticated = false;
        draft.currentUser.paths = null;
        draft.currentUser.roles = null;
        draft.currentUser.displayName = null;
        draft.currentUser.system = null;
        draft.currentUser.flattedPaths = undefined;
        draft.currentUser.pathLoaded = false;
        draft.currentUser.tag = null;
        draft.currentUser.token = null;
        draft.currentUser.session = null;
        draft.currentUser.blocked = null;
        draft.currentUser.linkUser = null;
        draft.currentUser.passwordIsReset = null;
        draft.currentUser.requireMfa = null;
        draft.currentUser.apiKey = null;
        draft.currentUser.email = null;
        draft.currentUser.employee = null;
        draft.currentUser.employee_number = null;
        draft.currentUser.idp = null;
        draft.currentUser.google = null;
        draft.currentUser.bukrs = "";
        draft.currentUser.expiresAt = "";
        draft.currentUser.pernr = "";

        draft.alterSystems = [];
        draft.id_token = "";

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case GET_CURRENT_USER:
        const userFromLocal = JSON.parse(
          localStorage.getItem(ITEM_KEY_CURRENT_USER)
        );
        if (userFromLocal) {
          draft.currentUser = { ...initialState.currentUser, ...userFromLocal };
          if (userFromLocal.partnerID && userFromLocal.systemID) {
            draft.selectedLogInSystem = [
              userFromLocal.systemID,
              userFromLocal.partnerID
            ].join("@");
          }
        } else {
          draft.currentUser = { ...initialState.currentUser };
        }

        if (!draft.currentUser.lang) {
          // ISO 639-1 standard language codes

          const navigatorLang = navigator.language || navigator.userLanguage;

          draft.currentUser.lang =
            navigatorLang &&
            (navigatorLang.length > 2
              ? navigatorLang.slice(0, 2)
              : navigatorLang);
        }

        if (
          draft.currentUser.supportLanguages &&
          draft.currentUser.supportLanguages.filter(
            lang => draft.currentUser.lang === lang
          ).length === 0
        ) {
          draft.currentUser.lang = draft.currentUser.defaultLanguage;
        }

        draft.currentUser.paths = undefined;
        draft.currentUser.flattedPaths = undefined;
        draft.currentUser.roles = undefined;
        draft.currentUser.pathLoaded = false;

        draft.showRootNotice = true;
        break;
      case CLOSE_ROOT_NOTICE:
        draft.showRootNotice = false;
        break;
      case SET_ALTER_SYSTEMS:
        draft.alterSystems = action.alterSystems;
        draft.currentUser.idp = action.idp;
        draft.id_token = action.id_token;
        break;
      case SET_CURRENT_THEME:
        draft.currentUser.theme = action.theme;

        localStorage.setItem(
          ITEM_KEY_CURRENT_USER,
          JSON.stringify(draft.currentUser)
        );
        break;
      case GET_IDP_REDIRECTED:
        if (action.idpRedirected === "true") {
          draft.idpRedirected = true;
        } else {
          draft.idpRedirected = false;
        }
        break;
      default:
        break;
    }
  });
}
