import { tryit } from "ui5-lib-rc";
import { convert_url, sendToWebSocket } from "actions/api";
import { runProcess, addWSRequest } from "actions/api";

export const NEW_SYSTEM_ID = "NEW_SYSTEM_ID";
export const newSystemID = ({ systemID, system, partnerID }) => {
  return {
    type: NEW_SYSTEM_ID,
    systemID,
    system,
    partnerID
  };
};

export const NEW_PID = "NEW_PID";
export const newPID = pid => {
  return {
    type: NEW_PID,
    pid
  };
};

export const SET_REQUIRE_MFA = "SET_REQUIRE_MFA";
export const setRequireMfa = () => {
  return {
    type: SET_REQUIRE_MFA
  };
};

export const NEW_USER = "NEW_USER";
export const newUser = (username, partnerID, systemID) => {
  return {
    type: NEW_USER,
    username,
    partnerID,
    systemID
  };
};

// getModifierState('CapsLock')
export const ACTIVATED_CAPS_LOCK = "ACTIVATED_CAPS_LOCK";
export const activatedCapsLock = isActivated => {
  return {
    type: ACTIVATED_CAPS_LOCK,
    isActivated
  };
};

// sessionDisplay changing
export const DISPLAY_SESSION_INPUT = "DISPLAY_SESSION_INPUT";
export const displaySessionInput = visible => {
  return {
    type: DISPLAY_SESSION_INPUT,
    visible
  };
};

// set selected SystemID
export const SELECT_LOGIN_SYSTEM = "SELECT_LOGIN_SYSTEM";
export const selectLogInSystem = systemID => {
  return {
    type: SELECT_LOGIN_SYSTEM,
    systemID
  };
};

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export function getCurrentUser() {
  return {
    type: GET_CURRENT_USER
  };
}

export const CLOSE_ROOT_NOTICE = "CLOSE_ROOT_NOTICE";
export function closeRootNotice() {
  return {
    type: CLOSE_ROOT_NOTICE
  };
}

export const CANCEL_CHANGING_INITIAL_PASSWORD =
  "CANCEL_CHANGING_INITIAL_PASSWORD";
export function cancelChangingInitialPassword() {
  return {
    type: CANCEL_CHANGING_INITIAL_PASSWORD
  };
}

export const CHANGE_INITIAL_PASSWORD = "CHANGE_INITIAL_PASSWORD";
export function changeInitialPassword(newPassword) {
  return {
    type: CHANGE_INITIAL_PASSWORD,
    newPassword
  };
}

export const SIGN_IN = "SIGN_IN";
export function signIn(user) {
  return {
    type: SIGN_IN,
    user
  };
}

export const SIGN_OUT = "SIGN_OUT";
export function signOut() {
  return {
    type: SIGN_OUT
  };
}

export const UPDATE_EXPIRES_AT = "UPDATE_EXPIRES_AT";
export function updateExpiresAt(expiresAt) {
  return {
    type: UPDATE_EXPIRES_AT,
    expiresAt
  };
}

export const UPDATE_EMPLOYEE_INFO = "[user] Update Employee Info";
export function updateEmployeeInfo(identity) {
  return {
    type: UPDATE_EMPLOYEE_INFO,
    identity
  };
}

export const SET_CURRENT_THEME = "SET_CURRENT_THEME";
export const setCurrentTheme = theme => {
  return {
    type: SET_CURRENT_THEME,
    theme
  };
};

export const STORE_PATHS_FOR_CURRENT_USER = "STORE_PATHS_FOR_CURRENT_USER";
export function storePathsForCurrentUser(paths, roles, subPathCollection) {
  return {
    type: STORE_PATHS_FOR_CURRENT_USER,
    paths,
    roles,
    subPathCollection
  };
}

export const SET_ALTER_SYSTEMS = "SET_ALTER_SYSTEMS";
export function setAlterSystems({ systems, idp, id_token }) {
  return {
    type: SET_ALTER_SYSTEMS,
    alterSystems: systems,
    idp,
    id_token
  };
}

export const GET_IDP_REDIRECTED = "GET_IDP_REDIRECTED";
export function getIdpRedirected(idpRedirected) {
  return {
    type: GET_IDP_REDIRECTED,
    idpRedirected
  };
}

export const asyncSignIn = values => {
  return (dispatch, getState) => {
    const processID = "sign_in";
    const sapLanguage = "en";

    const params = {
      headers: {
        "x-csrf-token": "fetch",
        Authorization: `Basic ${btoa(values.username + ":" + values.password)}`,
        Accept: "application/json"
      }
    };

    dispatch(
      addWSRequest({
        processID,
        reqHandler: () => {
          return reqSignin({
            ws: getState().api.ws,
            processID,
            params,
            sapLanguage
          });
        },
        resHandler: resSignin,
        resCallback: {
          afterSucceed: token => {
            dispatch(
              signIn({
                username: values.username,
                password: values.password
              })
            );
          }
        }
      })
    );

    dispatch(runProcess());
  };
};

export const reqSignin = ({
  ws,
  params = {},
  sapLanguage = "en",
  processID
}) => {
  return (dispatch, getState) => {
    const currentPID = getState().user.currentUser.pid;

    const odata_url = `https://${currentPID}.${
      process.env.REACT_APP_BYD_REPORT_ODATA_PATH

      // }/taskconfirm/TaskConfirmDesktopRootCollection?$top=2`,
    }/RPCRMCIVIB_Q0002QueryResults`;
    const actionConfig = {
      action: "requestodata",
      toDo: "getSignin",
      processID
    };

    sendToWebSocket(
      getState().api.ws,
      {
        httpMethod: "GET",
        queryStringParameters: {
          url: convert_url(odata_url, {
            // "sap-language": sapLanguage, //user.lang,

            $top: "1"
          }),
          headers: params.headers
        }
      },
      {
        dispatch,
        getState,
        actionConfig,
        message: "Get to Sign-In"
      }
    );
  };
};

export const resSignin = (json_data, callback = {}) => {
  return (dispatch, getState) => {
    const token = tryit(() => {
      return json_data.headers["x-csrf-token"];
    });

    if (token) {
      callback.afterSucceed && callback.afterSucceed(token);
    }
  };
};

export const asyncSignOut = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState().user;

    dispatch(signOut());
    if (window.google) {
      window.google.accounts.id.disableAutoSelect();
    }
    if (currentUser.system && currentUser.system.useSso) {
      const stage = process.env.REACT_APP_API_STAGE_NAME;
      const baseUrl = "https://idp.bsg.support";
      const formAction = [baseUrl, `/${stage}/slo/post`].join("");
      const ssoForm = document.createElement("form");
      ssoForm.method = "POST";
      ssoForm.action = formAction;
      ssoForm.target = "_blank";
      [{ name: "open_as_new_tab", value: true }].forEach(info => {
        const elInput = document.createElement("input");
        elInput.type = "hidden";
        elInput.name = info.name;
        elInput.value = info.value;
        ssoForm.appendChild(elInput);
      });
      document.body.appendChild(ssoForm);
      ssoForm.submit();
      document.body.removeChild(ssoForm);
    }
  };
};
