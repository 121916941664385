import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { useReducerWithLogger } from "@bsgp/lib-hooks";
import { reducer, initialState } from "./slices";

import Builder from "./builder/index";
import Renderer from "./renderer/index";
import Viewer from "./viewer/index";

function FormTableMain(props) {
  const path = "/lc-ui5";

  const [_state, _dispatch] = useReducerWithLogger(
    reducer,
    initialState,
    false
  );

  return (
    <Switch>
      <Route
        exact
        path={`${path}/builder/create`}
        render={routeProps => (
          <Builder
            {...{ ...routeProps, ...props, path, isCreate: true }}
            {...{ _state, _dispatch }}
          />
        )}
      />
      <Route
        exact
        path={`${path}/builder/:id`}
        render={routeProps => (
          <Builder
            {...{ ...routeProps, ...props, path }}
            {...{ _state, _dispatch }}
          />
        )}
      />
      <Route
        exact
        path={`${path}/preview/:entryPath+`}
        render={routeProps => (
          <Renderer
            {...{ ...routeProps, ...props, path }}
            {...{ _state, _dispatch }}
          />
        )}
      />

      <Route
        exact
        path={path}
        render={() => (
          <Redirect
            push={false}
            to={{
              pathname: `${path}/list`
            }}
          />
        )}
      />
      <Route
        exact
        path={`${path}/list`}
        render={routeProps => (
          <Viewer
            {...{ ...routeProps, ...props, path }}
            {...{ _state, _dispatch }}
          />
        )}
      />
      <Route
        exact
        key={props.location.pathname}
        path={`/:entryPath+`}
        render={routeProps => (
          <Renderer
            {...{ ...routeProps, ...props, path }}
            {...{ _state, _dispatch }}
          />
        )}
      />
      {/* <Route
        exact
        path={`${path}/z:path+`}
        render={routeProps => (
          <Renderer {...{ ...routeProps, ...props, path }} 
          {...{ _state, _dispatch }} />
        )}
      /> */}
    </Switch>
  );
}

const mapStateToProps = state => ({
  render5Version: "20190808",
  currentUser: state.user.currentUser
});
export default compose(connect(mapStateToProps))(FormTableMain);
