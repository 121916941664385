const {
  useRef,
  useReducer,
  useMemo,
  // useEffect,
  useCallback
} = require("react");
const { flushSync } = require("react-dom");

/*
아래 링크를 참조하여 logMiddleware를 작성합니다.
https://transang.me/get-state-callback-with-usereducer-in-react/
*/
const logMiddleware = state => getState => next => (action, sync) => {
  if (sync === false) {
    next(action);
  } else {
    flushSync(() => {
      next(action);
    });
  }

  // *NOTE*: because `dispatch(action)`` is not synchronous.
  // it does not gurantee that this getState() call
  // return the value after the action is applied.
};

const middlewares = [logMiddleware];

function useReducerWithLogger(...args) {
  const [reducer, initialState, sync] = args;
  const prevState = useRef(initialState);
  const getState = useCallback(() => prevState.current, []);

  const enhancedReducer = useRef((state, action) => {
    try {
      const newState = reducer(state, action);

      console.groupCollapsed("%caction:", "color:yellow", action.type);
      console.log("Payload: ", action);
      console.log("Previous State: ", prevState.current);
      console.log("Next State: ", newState);
      console.groupEnd();

      prevState.current = newState;
      return newState;
    } catch (ex) {
      console.groupCollapsed("%cstart action:", "color:yellow", action.type);
      console.log("Payload: ", action);
      console.groupEnd();
      throw ex;
    }
  }).current;

  const newArgs = [enhancedReducer, initialState];
  const [state, dispatch] = useReducer(...newArgs);

  const middlewaresRef = useRef(middlewares);
  const enhancedDispatch = useMemo(
    () =>
      middlewaresRef.current.reduceRight(
        (acc, mdw) => (action, _sync) => {
          return mdw(state)(getState)(acc)(
            action,
            _sync === undefined ? sync : _sync
          );
        },
        dispatch
      ),
    []
  );

  return [state, enhancedDispatch, getState];
}
module.exports.useReducerWithLogger = useReducerWithLogger;
