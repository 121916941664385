import React, { useMemo, memo } from "react";
import { ObjectStatus } from "@ui5/webcomponents-react";
import { removeDataBinding } from "@/lib/utils";
import { convertNumber } from "@bsgp/form-and-table/fieldComponent";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { IObjectNumberProps, IObjectNumberProperties } from "@/types/component";
import { hAlignToTextAlignMap } from "@/maps/legacyMap";

const useStyles = createUseStyles({
  number: {
    fontWeight: "bold"
  },
  unit: {
    marginLeft: "0.2rem",
    fontWeight: "lighter",
    fontSize: "0.8rem"
  }
});

const ObjectNumber = (props: IObjectNumberProps) => {
  const classes = useStyles();
  const {
    value,
    properties,
    hAlign,
    rowData = {},
    styleClasses,
    style,
    ...rest
  } = props;
  const newProperties = removeDataBinding(
    properties
  ) as IObjectNumberProperties;
  const newUnit = rowData[newProperties.unit as string];
  const newNumber = useMemo(
    () =>
      convertNumber(value, newUnit, {
        isAmount: newProperties.isAmount,
        isQuantity: newProperties.isQuantity,
        asA1: newProperties.asA1
      }),
    [rowData, value, newProperties]
  );

  return (
    <ObjectStatus
      className={clsx(...styleClasses, "ft_display_bottom_dash_on_table")}
      style={{
        width: "100%",
        textAlign: hAlign ? hAlignToTextAlignMap[hAlign] : "start",
        ...style
      }}
      {...rest}
    >
      <span className={classes.number}>{newNumber}</span>
      <span className={classes.unit}>{newUnit}</span>
    </ObjectStatus>
  );
};

export default memo(ObjectNumber);
