import React, { useEffect, useLayoutEffect } from "react";
import "App.css";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { tryit } from "@bsgp/lib-core";
import QsDs from "@bsgp/quicksight-dashboard";

import Auth from "components/auth";
import Privacy from "components/Privacy";
import TestPages from "test-pages";
import { initUI5 } from "App.ui5";
import MessageIndicator from "MessageIndicator";
import MenuButton from "MenuButton";
import HomeButton from "HomeButton";
import SettingsButton from "SettingsButton";
import { titleRef } from "title";
import {
  Page,
  Bar,
  Icon,
  Text,
  IconDesign,
  Title,
  TitleLevel,
  Link,
  BusyIndicator,
  ValueState,
  ObjectStatus,
  BarDesign
} from "@ui5/webcomponents-react";
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import MessageToast from "MessageToast";
import NoticeDialog from "NoticeDialog";
import MessageBox from "MessageBox";

function App(props) {
  useLayoutEffect(() => {
    initUI5(props);
  }, []);

  const { theme, isProduction } = props;

  useEffect(() => {
    setTheme(theme);
    window.sap.ui.require(["sap/ui/core/Core"], function(core) {
      core.applyTheme(theme);
    });
  }, [theme]);

  return (
    <BusyIndicator style={{ display: "block", height: "100%" }} active={false}>
      <Router basename={tryit(() => encodeURI(process.env.PUBLIC_URL)) || "/"}>
        <MessageToast />
        <NoticeDialog />
        <MessageBox />
        <Page
          className={"bsgSupportPage"}
          header={
            <Bar
              design={BarDesign.Header}
              className={["bsgSupportHeader", theme].join(" ")}
              endContent={<SettingsButton />}
              startContent={
                <>
                  <HomeButton />
                  <MenuButton />
                </>
              }
            >
              {isProduction && (
                <ObjectStatus
                  icon={
                    <Icon name="high-priority" design={IconDesign.Critical} />
                  }
                  state={ValueState.Warning}
                  inverted={true}
                  className="sapUiTinyMarginEnd"
                >
                  {"PRD"}
                </ObjectStatus>
              )}

              <Title ref={titleRef} level={TitleLevel.H5}></Title>
            </Bar>
          }
          disableScrolling={false}
          floatingFooter={false}
          backgroundDesign="Transparent"
          footer={
            <Bar
              design={BarDesign.Footer}
              className={"bsgSupportFooter"}
              endContent={
                <>
                  <Text className="appVersion">
                    {process.env.REACT_APP_RELEASE_VERSION ||
                      process.env.REACT_APP_STAGING_VERSION ||
                      ""}
                  </Text>
                  <Link href="/">Home</Link>
                  <Link href="/privacy">Privacy</Link>
                </>
              }
              startContent={<MessageIndicator />}
            ></Bar>
          }
        >
          {/* <Route
            render={({ location }) => {
              const newQueryString = this.handleQueryString(location.search);

              return (
                newQueryString &&
                newQueryString !== location.search && (
                  <Redirect
                    push={false}
                    to={{
                      pathname: localStorage.pathname,
                      search: newQueryString
                    }}
                  />
                )
              );
            }}
          /> */}
          <div id="content"></div>
          <Switch>
            <Route exact path={"/qsds/:qsAlias"} component={QsDs} />
            <Route exact path={"/privacy"} component={Privacy} />
            <Route strict path={"/test"} component={TestPages} />
            {/* {pid || systemID ? ( */}
            <Route strict path={"/"} component={Auth} />
            {/* ) : (
              <Route strict path={"/"} component={Login} />
            )} */}
            {/* <Route exact path={"/notauthorized"} component={Page401} />
            <Route exact path={"/pagenotfound"} component={Page404} />
            {/* <Redirect to="/" />*/}
          </Switch>
        </Page>
      </Router>
    </BusyIndicator>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.user.currentUser.theme,
    systemID: state.user.currentUser.systemID,
    pid: state.user.currentUser.pid,
    isProduction: state.user.currentUser.system?.isProduction,
    currentUsername: state.user.currentUser.username
  };
}

export default connect(mapStateToProps)(App);
