import React, { ReactNode } from "react";
import { Form } from "@/components";
import clsx from "clsx";
import { IFormObject } from "@/types/form";
import FormGroupBuilder from "./FormGroupBuilder";

interface IFormBuilderProps {
  formObject: Omit<IFormObject, "onSelect">;
  title?: string;
}

function FormBuilder({ formObject, title }: IFormBuilderProps): ReactNode {
  const formName = formObject.name;

  const sizeV2 = formObject.sizeV2 === true;

  const layout = getFormLayout(formObject);
  return (
    <div>
      <Form
        name={formName}
        key={formName}
        title={title}
        className={clsx([...getStyleClassNames(formObject)])}
        style={{
          flex: 0,
          padding: "1rem 0.75rem",
          width: sizeV2 || formObject.containers.length > 1 ? "100%" : "auto"
        }}
        {...layout}
        {...formObject}
      >
        <FormGroupBuilder
          formObject={formObject}
          formName={formName}
          sizeV2={sizeV2}
        />
      </Form>
    </div>
  );
}

const getFormLayout = formObject => {
  const defaultLabelSpan = formObject.labelSpanM || 2;
  return {
    ...formObject,
    ...(formObject.sizeV2
      ? {
          columnsL: 3,
          labelSpanL: 4,
          columnsXL: 4
        }
      : formObject.containers.length === 1
      ? {
          columnsS: 1,
          columnsM: 1,
          columnsL: 1,
          columnsXL: 1,
          labelSpanS: defaultLabelSpan,
          labelSpanM: defaultLabelSpan,
          labelSpanL: defaultLabelSpan,
          labelSpanXL: defaultLabelSpan
        }
      : {})
  };
};

const getStyleClassNames = formObject => {
  const classList = [
    "ft-transition-for-hide",
    "ft-label-word-break",
    "fields-relative-position",
    "ft-form-elements-overflow-visible"
  ];
  // const range = window.sap.ui.Device.media.getCurrentRange(
  //   window.sap.ui.Device.media.RANGESETS.SAP_STANDARD
  // );
  // onResize(range, comp);

  if (formObject.compactSize === true) {
    classList.push("sapUiSizeCompact");
  }

  if (formObject.tightContainers === true) {
    classList.push("container-fit-content");
  }

  return classList;
};

export default FormBuilder;
