import React from "react";
import { constructNodeEditorData } from "@bsgp/form-and-table/common";
import { IConstructorProps } from "@/types/common";
import NodeEditorIterator from "./Iterator";

const NodeEditorConstructor = (props: IConstructorProps) => {
  const { data } = props;
  if (!data.nodeeditor) return;

  const nodeeditorData = constructNodeEditorData(data.nodeeditor, {
    isReact: true
  });

  return <NodeEditorIterator nodeEditor={nodeeditorData} />;
};

export default NodeEditorConstructor;
