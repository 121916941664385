import React, { useEffect } from "react";
import { compose } from "@reduxjs/toolkit";
import { withRouter } from "react-router-dom";
import { FormTable, formTable, ft } from "@bsgp/form-and-table";
import * as controller from "./controller";
import { builderActions } from "./slice";
import { isTruthy, conv } from "@bsgp/lib-core";
import {
  getMixedOptions,
  getOptionsOnDialog,
  addMetaTable,
  getParentObjects,
  getTargetObj,
  getTargetArray,
  ComponentsList,
  TypeList,
  metaIndexMap,
  getCombinedKeyString,
  getFunctionHeader
} from "./modules";
import { extractAttributes, MODES } from "../mods";

const Builder = props => {
  const { _state, _dispatch, match, isCreate } = props;
  const { meta } = _state;
  const {
    headers,
    forms,
    tables,
    dialogs,
    extra,
    functions,
    codeeditors,
    nodeeditors
  } = meta;
  const { dialog } = extra;

  const builder = formTable({ wrapSingleTable: true });
  const dialogBuilder = formTable({ wrapSingleTable: true });
  const codeBuilder = formTable({ wrapSingleTable: true });
  const createVersionBuilder = formTable({ wrapSingleTable: true });
  const moveBuilder = formTable({ wrapSingleTable: true });
  const deployBuilder = formTable({ wrapSingleTable: true });
  const exportBuilder = formTable({ wrapSingleTable: true });

  const errorNotice = {
    valueState: "Error",
    valueStateText:
      "숫자와 영문 대소문자 및 _ . : - 특수기호만 입력 가능하며, 첫 글자는 영문과 _ 기호만 입력 가능합니다."
  };
  const requiredNotice = {
    valueState: "Information",
    valueStateText: "반드시 입력해야 하는 필수값입니다"
  };

  useEffect(() => {
    if (isCreate) {
      // pass
    } else {
      _dispatch(
        builderActions._updateMetaProperty({
          key: "id",
          value: match.params.id
        })
      );
    }
    return () => {
      _dispatch(builderActions._initAll());
    };
  }, []);

  useEffect(() => {
    if (match.params.id) {
      controller.getMetaData({ getProps: () => props })({
        id: match.params.id,
        isCreate
      })();
    }
  }, [match.params.id]);

  const { metaObj: currentDialog } = getTargetObj(meta);
  const currentDialogOptions = getOptionsOnDialog(currentDialog);

  // ! Builder 페이지
  builder
    .addFooterButton("saveBtn", {
      text: "Save",
      properties: {
        press: fn => fn.postMetaData({ isCreate }),
        icon: "sap-icon://save"
      }
    })
    .addFooterButton("toList", {
      text: "List",
      confirmMessage: "Are you sure to move?",
      isMain: false,
      properties: {
        press: fn => fn.moveToList,
        icon: "sap-icon://list"
      }
    })
    .addFooterButton("refresh", {
      value: "",
      isMain: false,
      icon: "sap-icon://refresh",
      onPress: fn => fn.getMetaData({ id: match.params.id, isCreate })
    })
    .addFooterAction("addFooterMain", {
      text: "Add Header",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["headers"],
          indecies: [],
          subKey: "header"
        }),
      icon: "sap-icon://header"
    })
    .addFooterAction("addFooterForm", {
      text: "Add Form",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["forms"],
          indecies: [],
          subKey: "form"
        }),
      icon: "sap-icon://form"
    })
    .addFooterAction("addFooterTable", {
      text: "Add Table",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["tables"],
          indecies: [],
          subKey: "table"
        }),
      icon: "sap-icon://table-view"
    })
    .addFooterAction("addDialog", {
      text: "Add Dialog",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["dialogs"],
          indecies: [],
          subKey: "dialog"
        }),
      icon: "sap-icon://popup-window"
    })
    .addFooterAction("addCodeEditor", {
      text: "Add CodeEditor",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["codeeditors"],
          indecies: [],
          subKey: "codeeditor"
        }),
      icon: "sap-icon://source-code"
    })
    .addFooterAction("addNodeEditor", {
      text: "Add NodeEditor",
      confirmMessage: "Are you sure to add?",
      onPress: fn =>
        fn.addExtraObject({
          keys: ["nodeeditors"],
          indecies: [],
          subKey: "nodeeditor"
        }),
      icon: "sap-icon://tree"
    })
    .addFooterAction("addFooterInit", {
      text: "Add Init Function",
      confirmMessage: "Are you sure to add?",
      properties: {
        enabled: !meta.functions.initialization.content
      },
      onPress: fn => fn.openCodeEditorDialog("initialization")
    })
    .addFooterAction("addFunctions", {
      text: "Add Functions",
      confirmMessage: "Are you sure to add?",
      properties: {
        enabled: !meta.functions.global.content
      },
      onPress: fn => fn.openCodeEditorDialog("global")
    });

  builder
    .addForm("builderForm")
    .addContainer("builderContainer1")
    .addField("id", {
      label: "ID",
      value: meta.id,
      component: ft.Input({
        onChange: fn => fn.updateMetaProperty("id"),
        properties: {
          editable: false,
          width: "10rem"
        }
      })
    })
    .addField("preview", {
      label: "",
      value: [meta.extra.selectedPath, "Preview"],
      component: [
        ft.Select("paths", {
          onChange: fn => fn.updateMetaProperty("selectedPath", true),
          list: meta.paths.map(path => path.origin).concat(""),
          properties: {
            width: "12rem"
          }
        }),
        ft.Button("do", {
          onPress: fn => fn.moveToPreviewFromForm
        })
      ]
    })
    .addField("editFunctions", {
      label: "",
      value: ["Edit Init. Func.", "Edit Global Func."],
      vertical: true,
      component: [
        ft.Button("initialization", {
          onPress: fn => fn.openCodeEditorDialog("initialization"),
          properties: {
            icon: "sap-icon://edit",
            enabled: !!meta.functions.initialization.content
          }
        }),
        ft.Button("global", {
          onPress: fn => fn.openCodeEditorDialog("global"),
          properties: {
            icon: "sap-icon://edit",
            enabled: !!meta.functions.global.content
          }
        })
      ]
    })
    .addContainer("builderContainer2")
    .addField("description", {
      label: "Description",
      value: meta.description,
      component: ft.Input({
        onChange: fn => fn.updateMetaProperty("description"),
        properties: {
          editable: true,
          width: "10rem",
          placeholder: "description"
        }
      })
    })
    .addField("wrapForms", {
      label: "Wrap Forms",
      value: meta.wrapForms === false ? false : true,
      component: ft.CheckBox({
        onChange: fn => fn.updateMetaProperty("wrapForms"),
        properties: {
          editable: true
        }
      })
    })
    .addField("renderAsReact", {
      label: "Render as React",
      value: meta.renderAsReact === false ? false : true,
      component: ft.CheckBox({
        onChange: fn => fn.updateMetaProperty("renderAsReact"),
        properties: {
          editable: true
        }
      })
    })
    .addContainer("builderContainer3")
    .addField("errorMessage", {
      label: undefined,
      value: meta.extra.errorMessage || meta.extra.message || "Good",
      component: ft.Text({
        colorful: true,
        properties: {
          type: meta.extra.errorMessage
            ? window.sap.ui.core.MessageType.Error
            : window.sap.ui.core.MessageType.Success
        }
      })
    })
    .addField("copyToDev", {
      label: undefined,
      value: "<<<<< Copy Meta To Dev >>>>>",
      component: ft.Button({
        onPress: fn => fn.onCopyToDev
      })
    });

  builder
    .addTable("paths", { title: "Paths" })
    .addToolbarButton("addPath", {
      icon: "sap-icon://add",
      text: "Path 추가",
      onPress: fn => fn.onAddPath
    })
    .addColumn("skid", {
      text: "ID",
      width: "5rem",
      component: ft.Text()
    })
    .addColumn("origin", {
      width: "15rem",
      text: "Path",
      component: ft.Input({
        onChange: fn => fn.onUpdatePath("origin")
      })
    })
    .addColumn("mode", {
      width: "5rem",
      text: "Mode",
      component: ft.Select({
        onChange: fn => fn.onUpdatePath("mode"),
        list: [""].concat(Object.keys(MODES))
      })
    })
    .addColumn("title", {
      text: "Title",
      component: ft.Input({
        onChange: fn => fn.onUpdatePath("title")
      })
    })
    .addColumn("previewBtn", {
      width: "5rem",
      text: "",
      component: ft.Button({
        properties: {
          text: "Preview"
        },
        onPress: fn => fn.moveToPreview
      })
    })
    .addItems(meta.paths);

  builder
    .addTable("versions", { title: "Versions" })
    .addToolbarButton("createVersion", {
      value: "Create Version",
      icon: "sap-icon://add-document",
      onPress: fn => fn.toggleCreateVersionDialog(true)
    })
    .addColumn("lstvsn", {
      text: "ID",
      width: "10rem"
    })
    .addColumn("description", {
      text: "Description"
    })
    .addColumn("deployed", {
      width: "5rem",
      text: "Deployed",
      component: ft.CheckBox({
        properties: {
          enabled: false
        }
      })
    })
    .addColumn("preDeployed", {
      width: "5rem",
      text: "Pre-Deployed",
      component: ft.CheckBox({
        properties: {
          enabled: false
        }
      })
    })
    .addColumn("deployBtn", {
      width: "5rem",
      text: "",
      vertical: true,
      component: [
        ft.Button("deploy", {
          properties: {
            text: "Deploy",
            enabled: ["{= ", "!$", "{deployed}", " }"].join("")
          },
          confirmMessage: "Are you sure to deploy?",
          onPress: fn => fn.deployVersion
        }),
        ft.Button("preDeploy", {
          properties: {
            text: "Pre-Deploy",
            enabled: [
              "{= ",
              "!$",
              "{deployed}",
              " && ",
              "!$",
              "{disallowPreDeploy}",
              " }"
            ].join("")
          },
          onPress: fn => fn.toggleDeployDialog(true)
        })
      ]
    })
    .addItems(meta.versions);

  if (isTruthy(forms)) {
    forms.forEach((curForm, curFormIdx) => {
      addMetaTable(builder, curForm, curFormIdx, "forms");
    });
  }

  if (isTruthy(tables)) {
    tables.forEach((curTable, curTableIdx) => {
      addMetaTable(builder, curTable, curTableIdx, "tables");
    });
  }

  if (isTruthy(headers)) {
    headers.forEach((curHeader, curHeaderIdx) => {
      addMetaTable(builder, curHeader, curHeaderIdx, "headers");
    });
  }

  if (isTruthy(dialogs)) {
    dialogs.forEach((curDialog, curDialogIdx) => {
      addMetaTable(builder, curDialog, curDialogIdx, "dialogs");
    });
  }

  if (isTruthy(codeeditors)) {
    codeeditors.forEach((curCode, curCodeIdx) => {
      addMetaTable(builder, curCode, curCodeIdx, "codeeditors");
    });
  }

  if (isTruthy(nodeeditors)) {
    nodeeditors.forEach((curNode, curNodeIdx) => {
      addMetaTable(builder, curNode, curNodeIdx, "nodeeditors");
    });
  }

  // ! Edit Dialog에서 Forms
  if (dialog.isOpen) {
    // * dialog update(form, table, headers ...)

    const { metaObj: dialogObj, lastKey, parentObj } = getTargetObj(
      meta,
      dialog.target.keys
    );
    const { metaArray } = getTargetArray(meta, dialog.target.keys);

    dialogBuilder
      .addForm("dialogForm")
      .addContainer("dialogContainer")
      .addField("dialogKey", {
        label: "Key",
        value: [dialogObj.key, dialogObj.ignore],
        component: [
          ft.Input("key", {
            properties: {
              ...(dialog.invalid?.key ? errorNotice : requiredNotice)
            },
            onChange: fn => fn.updateRequiredKey("key")
          }),
          ft.CheckBox("ignore", {
            text: "ignore",
            onChange: fn => fn.updateRequiredKey("ignore")
          })
        ]
      });

    if (["buttons", "toolbars"].includes(dialog.target.type)) {
      dialogBuilder.addField("isAction", {
        label: "Is Action",
        value: dialogObj.isAction,
        component: ft.CheckBox({
          onChange: fn => fn.updateRequiredKey("isAction")
        })
      });
    }

    if (["tables"].includes(dialog.target.type)) {
      dialogBuilder.addField("isSearch", {
        label: "Add SearchField",
        value: dialogObj.isSearch,
        component: ft.CheckBox({
          onChange: fn => fn.updateRequiredKey("isSearch")
        })
      });
    }
    if (["fields"].includes(dialog.target.type)) {
      dialogBuilder.addField("label", {
        label: "Label",
        value: dialogObj.label,
        component: ft.Input({
          onChange: fn => fn.updateRequiredKey("label")
        })
      });
    }
    if (["buttons", "columns", "toolbars"].includes(dialog.target.type)) {
      dialogBuilder.addField("text", {
        label: "Text",
        value: dialogObj.text,
        component: ft.Input({
          onChange: fn => fn.updateRequiredKey("text")
        })
      });
    }
    if (
      [
        "forms",
        "containers",
        "tables",
        "dialogs",
        "codeeditors",
        "nodeeditors"
      ].includes(dialog.target.type)
    ) {
      dialogBuilder.addField("title", {
        label: "Title",
        value: dialogObj.title,
        component: ft.Input({
          onChange: fn => fn.updateRequiredKey("title")
        })
      });
    }
    dialogBuilder.addContainer("c2");
    if (["forms"].includes(dialog.target.type)) {
      dialogBuilder.addField("default", {
        label: "Default",
        value: dialogObj.default,
        component: ft.CheckBox({
          onChange: fn => fn.updateRequiredKey("default")
        })
      });
    }
    if (
      ["fields", "containers", "columns", "components", "buttons"].includes(
        dialog.target.type
      )
    ) {
      dialogBuilder.addField("index", {
        label: "Index",
        value: dialog.target[metaIndexMap[lastKey]],
        component: ft.Input({
          properties: {
            width: "3rem",
            type: window.sap.m.InputType.Number
          },
          onChange: fn =>
            fn.onUpdateContainerIndex({
              maxIndex: metaArray.length - 1,
              currentIndex: dialog.target[metaIndexMap[lastKey]]
            })
        })
      });

      const { list: parentObjects, parentType } = getParentObjects(
        meta,
        dialog.target.keys
      );
      dialogBuilder.addField("move", {
        label: "Move to",
        value: "",
        component: ft.Select({
          properties: {},
          combine: true,
          list: parentObjects
            .filter(each => each.key !== parentObj.key)
            .map(each => ({ key: each.key, text: parentType }))
            .concat([""]),
          onChange: fn => fn.onMoveObjectToAnotherParent
        })
      });
    }
    if (
      ["forms", "tables"].includes(dialog.target.type) &&
      dialog.target.keys[0] === "dialogs"
    ) {
      dialogBuilder.addField("moveOutside", {
        label: "",
        value: "Move Outside",
        component: ft.Button({
          properties: {
            icon: "sap-icon://outdent",
            width: "9rem"
          },
          onPress: fn => fn.toggleMoveDialog(true)
        })
      });
    }
    if (["components"].includes(dialog.target.type)) {
      dialogBuilder.addField("type", {
        label: "Component",
        value: dialogObj.type,
        component: ft.ComboBox({
          list: ComponentsList,
          onChange: fn => fn.updateRequiredKey("type")
        })
      });
    }
    if (["fields", "columns"].includes(dialog.target.type)) {
      dialogBuilder.addField("binding", {
        label: "Binding",
        value: dialogObj.binding,
        component: ft.Input({
          properties: {},
          onChange: fn => fn.updateRequiredKey("binding")
        })
      });
    }
    if (["dialogs"].includes(dialog.target.type)) {
      dialogBuilder.addField("metaId", {
        label: "Meta ID",
        value: dialogObj.metaId,
        component: ft.Input({
          valueHelpV2: {
            dialogId: "metaList",
            onRequest: fn => fn.queryAllMeta,
            onConfirm: fn => fn.selectMeta
          },
          onChange: fn => fn.updateRequiredKey("metaId")
        })
      });

      builder.addDialog("metaList", {
        properties: {
          title: "Meta List"
        },
        ftData: formTable()
          .addTable("metaList", { usePagination: true, mode: "S" })
          .addToolbarSearch()
          .addColumn("id", { text: "ID" })
          .addColumn("description", { text: "Description" })
          .addItems(meta.extra.metaList.filter(each => each.id !== meta.id))
          .done()
      });
    }

    // ! Edit Dialog에서 Options(Table)
    const modifiedOptions = getMixedOptions(currentDialogOptions, {
      type: dialog.target.type,
      compType: currentDialog.type
    });
    const keyString = getCombinedKeyString(dialog.target.keys, meta, {
      target: dialog.target,
      combineAllKeys: true
    });

    const typeList = [{ key: "", text: "" }];
    if (dialog.target.type === "buttons") {
      typeList.push({ key: "Emphasized", text: "Emphasized" });
      typeList.push({ key: "Ghost", text: "Ghost" });
    } else if (dialog.target.type === "codeeditors") {
      typeList.push({ key: "hjson", text: "hjson" });
      typeList.push({ key: "javascript", text: "javascript" });
      typeList.push({ key: "json", text: "json" });
      typeList.push({ key: "terminal", text: "terminal" });
      typeList.push({ key: "terraform", text: "terraform" });
      typeList.push({ key: "xml", text: "xml" });
    }

    dialogBuilder
      .addTable(["options", keyString].join("_"), {
        title: ""
      })
      .addToolbarButton("addPropsButton", {
        icon: "sap-icon://add",
        text: "option 추가",
        onPress: fn => fn.addProperties
      })
      .addColumn(`key`, {
        text: "Key",
        width: "10rem",
        component: ft.ComboBox({
          list: modifiedOptions,
          onChange: fn => fn.updateKeyColumn
        })
      })
      .addColumn(`ignore`, {
        text: "ignore",
        width: "5rem",
        component: ft.CheckBox({
          onChange: fn => fn.updateIgnoreOption
        })
      })
      .addColumn(`type`, {
        text: "Type",
        width: "10rem",
        component: ft.Select({
          list: TypeList,
          onChange: fn => fn.updateValueType
        })
      })
      .addColumn(`value`, {
        text: "Value",
        component: [
          ft.Input({
            properties: {
              width: "15rem",
              visible: [
                "{=",
                ["$", "{key}"].join(""),
                "!==",
                "'valueHelpV2-dialogId'",
                "&&",
                ["$", "{key}"].join(""),
                "!==",
                "'type'",
                "&&",
                ["$", "{key}"].join(""),
                "!==",
                "'conv'",
                "&&",
                ["$", "{type}"].join(""),
                "!==",
                "'Function'",
                "&&",
                ["$", "{type}"].join(""),
                "!==",
                "'Array'",
                "}"
              ].join("")
            },
            onChange: fn => fn.updateValueColumn
          }),
          ft.Button({
            properties: {
              icon: "sap-icon://edit",
              text: "edit",
              visible: ["{= $", "{type} === 'Function' }"].join(""),
              width: "15rem"
            },
            onPress: fn => fn.openCodeEditorDialog()
          }),
          ft.ComboBox("c", {
            multiple: true,
            list: tables.map(each => {
              return { key: each.key, text: each.key };
            }),
            properties: {
              width: "15rem",
              visible: [
                "{= $",
                "{key} === 'table' && $",
                "{type} === 'Array' }"
              ].join(""),
              selectedKeys: {
                parts: [{ path: "value" }],
                formatter: value => {
                  if (value !== Object && typeof value === "boolean") {
                    return String(value);
                  }
                  return value;
                }
              }
            },
            onChange: fn => fn.updateValueColumn
          }),
          ft.Select("s1", {
            list: typeList,
            properties: {
              width: "15rem",
              visible: ["{= $", "{key}", "===", "'type'", "}"].join(""),
              selectedKey: {
                parts: [{ path: "value" }],
                formatter: value => value
              }
            },
            onChange: fn => fn.updateValueColumn
          }),
          ft.Select("s2", {
            list: dialogs.reduce(
              (acc, cur) => {
                const each = { key: cur.key, text: cur.key };
                acc.push(each);
                return acc;
              },
              [{}]
            ),
            properties: {
              width: "15rem",
              visible: [
                "{= $",
                "{key}",
                "===",
                "'valueHelpV2-dialogId'",
                "}"
              ].join(""),
              selectedKey: {
                parts: [{ path: "value" }],
                formatter: value => value
              }
            },
            onChange: fn => fn.updateValueColumn
          }),
          ft.Select("s3", {
            list: Object.keys(conv).reduce(
              (acc, each) => {
                acc.push({ key: each, text: each });
                return acc;
              },
              [{ key: "", text: "" }]
            ),
            properties: {
              width: "15rem",
              visible: ["{= $", "{key}", "===", "'conv'", "}"].join(""),
              selectedKey: {
                parts: [{ path: "value" }],
                formatter: value => value
              }
            },
            onChange: fn => fn.updateValueColumn
          })
        ]
      })
      .addItems(currentDialogOptions);

    //   break;
    // }
    // }
  }

  builder.addDialog("formDialog", {
    properties: {
      title: `${dialog.target.type} dialog`
    },
    isOpen: dialog.isOpen,
    onConfirm: fn => fn.confirmDialogUpdate,
    onCancel: fn => fn.cancelDialogUpdate,
    ftData: dialogBuilder.done()
  });

  if (dialog.isCreateVersionOpen) {
    createVersionBuilder
      .addForm("form")
      .addContainer("c1")
      .addField("description", {
        label: "Description",
        component: ft.Input({
          onChange: fn => fn.updateVersionValue("description")
        })
      });
  }

  builder.addDialog("versionDialog", {
    properties: {
      title: `Create Version`
    },
    fullSize: false,
    isOpen: dialog.isCreateVersionOpen,
    onConfirm: fn => fn.confirmCreateVersion,
    onCancel: fn => fn.toggleCreateVersionDialog(false),
    ftData: createVersionBuilder.done()
  });

  if (dialog.isMoveOpen) {
    moveBuilder.addForm("form").addContainer("c1");

    if (dialog.target.keys[0] === "dialogs") {
      moveBuilder.addField("index", {
        label: "Index",
        component: ft.Input({
          properties: {
            width: "3rem",
            type: window.sap.m.InputType.Number
          },
          onChange: fn => fn.updateMoveValue("index")
        })
      });
    } else {
      moveBuilder.addField("dialogKey", {
        label: "Dialog Key",
        component: ft.Select({
          list: dialogs.map(dlg => dlg.key).concat(""),
          onChange: fn => fn.updateMoveValue("dialogKey")
        })
      });
    }
  }

  builder.addDialog("moveDialog", {
    properties: {
      title: `Move`
    },
    fullSize: false,
    isOpen: dialog.isMoveOpen,
    onConfirm: fn => fn.confirmMove,
    onCancel: fn => fn.toggleMoveDialog(false),
    ftData: moveBuilder.done()
  });

  if (dialog.isExportOpen) {
    exportBuilder
      .addForm("exportForm")
      .addContainer("c1")
      .addField("globalFunctions", {
        label: "",
        value: [meta.extra.export.globalFunction, meta.extra.export.remain],
        component: [
          ft.CheckBox("global", {
            text: "Including Global Function",
            onChange: fn => fn.updateExportValue("globalFunction")
          }),
          ft.CheckBox("remain", {
            text: "Keep Origin Data",
            onChange: fn => fn.updateExportValue("remain")
          })
        ]
      });
  }
  builder.addDialog("exportDialog", {
    properties: {
      title: "Export"
    },
    fullSize: false,
    isOpen: dialog.isExportOpen,
    onConfirm: fn => fn.confirmExport,
    onCancel: fn => fn.toggleExportDialog(false),
    ftData: exportBuilder.done()
  });

  if (dialog.isDeployOpen) {
    deployBuilder
      .addForm("form")
      .addContainer("c1")
      .addField("version", {
        label: "Selected Version",
        value: [meta.extra.deploy.version, meta.extra.deploy.description].join(
          " "
        )
      })
      .addField("partner", {
        label: "Partner",
        value: meta.extra.deploy.partner,
        component: ft.Select({
          combine: true,
          list: [{ id: "", name: "" }].concat(
            meta.extra.deploy.partners.map(each => ({
              key: each.id,
              text: each.name
            }))
          ),
          onChange: fn => fn.updateDeployValue("partner")
        })
      })
      .addField("system", {
        label: "System",
        value: meta.extra.deploy.system,
        component: ft.Select({
          combine: true,
          list: [{ id: "", name: "" }].concat(
            meta.extra.deploy.systems.map(each => ({
              key: each.id,
              text: each.name
            }))
          ),
          onChange: fn => fn.updateDeployValue("system")
        })
      })
      .addField("add", {
        label: "",
        value: "Add",
        component: ft.Button({
          onPress: fn => fn.addSystemToSp
        })
      })
      .addField("deployedSystems", {
        label: "Deployed System",
        value: meta.extra.deploy.presys,
        component: ft.Input({
          multiple: true,
          onChange: fn => fn.updateDeployValue("presys")
        })
      });
  }

  builder.addDialog("deployDialog", {
    properties: {
      title: `Deploy Version`
    },
    fullSize: false,
    isOpen: dialog.isDeployOpen,
    onConfirm: fn => fn.confirmDeployVersion,
    onCancel: fn => fn.toggleDeployDialog(false),
    ftData: deployBuilder.done()
  });

  if (dialog.isCodeEditorOpen) {
    const funcBtns = [];
    const strParams = getFunctionHeader(dialog, {
      addBracket: true,
      addContextKeys: true
    });
    if (strParams) {
      funcBtns.push({
        name: "state",
        value: window.sap.ui.base.ManagedObject.escapeSettingsValue(strParams),
        component: ft.Button()
      });
    }
    codeBuilder.addCode(`addCode`, {
      title: "Function Editor",
      value: functions[dialog.target.functionUid].content,
      buttons: funcBtns,
      properties: {
        change: fn => fn.updateOptionsFunc
      }
    });

    codeBuilder.addCode(`lintResult`, {
      title: "Lint Result",
      value: dialog.target.resultText,
      selected: !!dialog.target.resultText,
      properties: {
        type: "text",
        editable: false
      }
    });

    if (dialog.target.isAsync) {
      codeBuilder
        .addTable("vars", {
          usePagination: {
            itemsPerPage: 5
          }
        })
        .addColumn("id", {
          text: "Returning Data Path",
          width: "15rem"
        })
        .addColumn("attributes", {
          text: "Attributes"
        })
        .addItems(
          extractAttributes(forms, tables, codeeditors, nodeeditors).concat(
            dialogs.reduce((acc, dlg) => {
              const row = {
                id: ["dialogs", dlg.key].join("."),
                attributes: ["isOpen"]
              };
              row.attributes = row.attributes.join(", ");
              acc.push(row);
              return acc.concat(
                extractAttributes(
                  dlg.forms,
                  dlg.tables,
                  dlg.codeeditors,
                  dlg.nodeeditors
                )
              );
            }, [])
          )
        );
    }
  }
  builder.addDialog("codeEditDialog", {
    properties: {
      title: `Function Editor`
    },
    isOpen: dialog.isCodeEditorOpen,
    onConfirm: fn => fn.confirmCodeEditorUpdate,
    onCancel: fn => fn.cancelCodeEditorUpdate,
    ftData: codeBuilder.done()
  });

  return (
    <FormTable
      {...props}
      fn={controller}
      title={isCreate ? "Meta 생성" : "Meta 관리"}
      data={builder.done()}
    ></FormTable>
  );
};

export default compose(withRouter)(Builder);
