import React from "react";
import ReactFlow, { Background, Controls } from "reactflow";
import "reactflow/dist/style.css";
import { useEdges, useNodes } from "@/hooks/nodeEditor";
import { INodeEditorProps } from "@/types/nodeEditor";
import Node from "@/components/nodeEditor/Node";

const nodeTypes = {
  custom: Node
};

const NodeEditor = (props: INodeEditorProps) => {
  const nodeProperties = useNodes(props);
  const edgeProperties = useEdges(props);
  const { style } = props;

  return (
    <div style={style}>
      <ReactFlow
        {...nodeProperties}
        {...edgeProperties}
        fitView
        snapToGrid
        nodeDragThreshold={1}
        data-cy="node-editor"
        nodeTypes={nodeTypes}
      >
        <Background />
        <Controls />
        {/* <AddPanel /> */}
      </ReactFlow>
    </div>
  );
};

export default NodeEditor;
