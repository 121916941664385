import { clone } from "@bsgp/lib-core";
import { isFunction } from "@bsgp/lib-core";
import {
  AnalyticalTableColumnDefinition,
  Ui5CustomEvent
} from "@ui5/webcomponents-react";
import { removeUndefinedKeys } from "@bsgp/form-and-table/lib/functions";
import {
  componentNameMap,
  dialogEventPropertyMap,
  eventPropertyMap
} from "@/maps/legacyMap";
import { ICustomEvent, IRefineEventForTableProps } from "@/types/event";
import { IColumnData } from "@/types/table";
import { IComponentProperties } from "@/types/component";
import { IValueHelpV2EventVal } from "@/types/dialog";

interface IRefineEventParams<T> {
  (
    properties: T,
    fn: object,
    extraParams?: {
      columns?: IColumnData[] | AnalyticalTableColumnDefinition[];
      tableData?: any[];
      rowData?: any;
      items?: any[];
      dataList?: any[];
      indices?: any[];
      usingPagination?: boolean;
      nextId?: number;
    },
    flags?: {
      shouldSkipLegacyFields?: boolean;
      shouldStoreInOEvent?: boolean;
      shouldUseDialogEventMap?: boolean;
    }
  ): T;
}

const getLegacyFieldName = (type: string) => {
  return componentNameMap[type] || "sap.m.Input";
};

const getLegacyEventFields = (event: Ui5CustomEvent): Partial<ICustomEvent> => {
  return {
    getSource: () => {
      return {
        getMetadata: () => {
          if (event?.target?.dataset?.type) {
            const { type } = event.target.dataset;
            return {
              getName: () => getLegacyFieldName(type)
            };
          }
        },
        getSelectedItem: () => {
          return { getKey: () => "key" };
        }
      };
    },
    getParameters: () => {
      const value = (event.target as HTMLInputElement).value;
      return {
        value,
        id: event.target.id,
        newValue: value
      };
    }
  };
};

const cloneEventAndAddLegacyFields = (event: Ui5CustomEvent) => {
  if (!event) return null;
  const newEvent = {};
  for (const field in event) {
    newEvent[field] = clone(event[field]);
  }
  const legacyFields = getLegacyEventFields(event);
  for (const legacyField in legacyFields) {
    if (!newEvent[legacyField]) {
      newEvent[legacyField] = clone(legacyFields[legacyField]);
    }
  }
  Object.setPrototypeOf(newEvent, event);
  return newEvent;
};

const refineEvent: IRefineEventParams<
  IComponentProperties | IValueHelpV2EventVal | IRefineEventForTableProps
> = (properties, fn, extraParams = {}, flags = {}) => {
  const {
    shouldSkipLegacyFields,
    shouldStoreInOEvent,
    shouldUseDialogEventMap
  } = flags;
  if (!properties) return properties;
  return removeUndefinedKeys(
    Object.keys(properties).reduce((acc, key) => {
      acc[key] = properties[key];
      // function이면 이벤트 핸들러로 가정.
      if (isFunction(properties[key])) {
        const curEventHandler = properties[key](fn);
        const newEventHandler = (event: Ui5CustomEvent) => {
          const newEvent = shouldSkipLegacyFields
            ? event
            : cloneEventAndAddLegacyFields(event);
          return curEventHandler(
            shouldStoreInOEvent
              ? { oEvent: newEvent, ...extraParams, renderAsReact: true }
              : { ...newEvent, ...extraParams, renderAsReact: true }
          );
        };
        acc[key] = newEventHandler;
        const eventMap = shouldUseDialogEventMap
          ? dialogEventPropertyMap
          : eventPropertyMap;
        if (eventMap[key] && !acc[eventMap[key]]) {
          acc[eventMap[key]] = newEventHandler;
        }
      }
      return acc;
    }, {})
  );
};

function refineEventForDialog(
  properties = {},
  fn,
  items = []
): IValueHelpV2EventVal {
  return refineEvent(
    properties,
    fn,
    { items },
    {
      shouldUseDialogEventMap: true
    }
  ) as IValueHelpV2EventVal;
}

export { refineEvent, refineEventForDialog };
