import { combineReducers } from "@reduxjs/toolkit";
import builderSlice from "./builder/slice";
import rendererSlice from "./renderer/slice";
import viewerSlice from "./viewer/slice";

// useReducer사용에 필요
const initialState = {
  meta: builderSlice.getInitialState(),
  data: rendererSlice.getInitialState(),
  viewer: viewerSlice.getInitialState()
};

const reducer = combineReducers({
  meta: builderSlice.reducer,
  data: rendererSlice.reducer,
  viewer: viewerSlice.reducer
});

export { initialState, reducer };
