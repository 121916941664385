import React from "react";
import DialogV2BuildIterator from "./DialogV2BuildIterator";
import { ICommonProps } from "@/types/common";
import { DialogContextProvider } from "@/contexts/dialogContext";

const DialogV2Constructor = (props: ICommonProps) => {
  const { data } = props;

  if (!data.dialogV2) return;

  return (
    <DialogContextProvider>
      <DialogV2BuildIterator dialogDataMap={data.dialogV2} />
    </DialogContextProvider>
  );
};

export default DialogV2Constructor;
