import React from "react";
import { Toolbar } from "@/components";
import { ToolbarSpacer } from "@ui5/webcomponents-react";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import OptionCustomBuilder from "./OptionCustomBuilder";
import { ICodeEditorButton, IComponentBuilderProps } from "@/types/common";

interface IEditorToolbarBuilderProps {
  buttons: ICodeEditorButton[] | ICodeEditorButton[][];
  ceId: string;
  isFullScreen: boolean;
  handleFullScreen: () => void;
}

const EditorToolbarBuilder = (props: IEditorToolbarBuilderProps) => {
  const { buttons, ceId, isFullScreen, handleFullScreen } = props;

  return (
    <Toolbar
      className="sapUiSizeCompact"
      design={isFullScreen ? "Info" : "Solid"}
    >
      <ToolbarComponentBuilder
        type="Button"
        properties={{
          icon: !isFullScreen
            ? "sap-icon://full-screen"
            : "sap-icon://exit-full-screen",
          onClick: handleFullScreen
        }}
        refineEventHookName="none"
      />
      {buttons.map((btn: ICodeEditorButton[] | ICodeEditorButton) => {
        const btnVal = Array.isArray(btn) ? btn[0] : btn;
        const isMultiple = Array.isArray(btn) && btn.length > 1;
        const ceToolbarContentKey = [
          ceId,
          btnVal.name || btnVal.component.name
        ].join("_");
        return isMultiple ? (
          <ToolbarComponentBuilder
            type="MenuButton"
            value={btnVal.text || btnVal.value}
            properties={{
              ...btnVal.properties,
              ...btnVal.component.properties,
              ...(btnVal.icon ? { icon: btnVal.icon } : {})
              //   useDefaultActionOnly: true,
              //   buttonMode: window.sap.m.MenuButtonMode.Split,
              //   defaultAction: btnVal.component.properties.press
            }}
            key={ceToolbarContentKey}
            items={btn
              .filter((each, index) => index > 0)
              .reduce((acc, each, index) => {
                acc[`${ceToolbarContentKey}_menuitem_${index}`] = {
                  value: each.text || each.value,
                  properties: {
                    icon: each.icon,
                    press: each.onPress,
                    ...each.properties,
                    ...each.component.properties
                  }
                };
                return acc;
              }, {})}
          />
        ) : (
          <ToolbarComponentBuilder
            {...(btn as IComponentBuilderProps)}
            {...(btn as ICodeEditorButton).component}
            key={ceToolbarContentKey}
          />
        );
      })}
      <ToolbarSpacer />
      <OptionCustomBuilder {...props} />
    </Toolbar>
  );
};

export default EditorToolbarBuilder;
