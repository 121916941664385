import React from "react";
import { TabContainer } from "@/components";
import {
  TabContainerBackgroundDesign,
  TabLayout,
  Tab
} from "@ui5/webcomponents-react";
import clsx from "clsx";
import { ITableBuildIteratorProps } from "@/types/table";
import TableBuilder from "./TableBuilder";

const TableWrapperBuilder = (props: ITableBuildIteratorProps) => {
  const { tableDataList } = props;
  // const isCozy = getIsCozy(tableDataList);
  const hasIcon = getHasIcon(tableDataList);
  return (
    <TabContainer
      tabLayout={hasIcon ? TabLayout.Standard : TabLayout.Inline}
      fixed={true}
      onTabSelect={oEvent => {
        const selectedIndex = oEvent.detail.tabIndex;

        const selectHandler = tableDataList[selectedIndex].onSelectTab;
        if (selectHandler) {
          const selectedKey = tableDataList[selectedIndex].name;
          selectHandler({ tableKey: selectedKey });
        }
      }}
      contentBackgroundDesign={TabContainerBackgroundDesign.Transparent}
      className={clsx("ft-selected-icon-tab", "noBottomLine")}
    >
      {tableDataList.map(tableObject => {
        const { title, icon, ...rest } = tableObject;

        return (
          <Tab
            key={tableObject.name}
            text={title}
            icon={icon}
            additionalText={
              tableObject.count === undefined
                ? undefined
                : tableObject.count.toString()
            }
            // design={
            //   hasIcon
            //     ? window.sap.m.IconTabFilterDesign.Horizontal
            //     : window.sap.m.IconTabFilterDesign.Vertical
            // }
          >
            <TableBuilder {...rest} />
          </Tab>
        );
      })}
    </TabContainer>
  );
};

// function getIsCozy(tableDataList) {
//   return tableDataList.reduce((acc, tableObject) => {
//     if (acc) {
//       return acc;
//     }
//     if (
//       tableObject.count !== undefined &&
//       tableObject.icon === undefined &&
//       tableObject.title
//     ) {
//       return true;
//     }
//     return false;
//   }, false);
// }

function getHasIcon(tableDataList) {
  return tableDataList.reduce((acc, tableObject) => {
    if (acc) {
      return acc;
    }
    if (tableObject.icon) {
      return true;
    }
    return false;
  }, false);
}

export default TableWrapperBuilder;
