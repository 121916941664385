import React from "react";
import { constructFooterData } from "@bsgp/form-and-table/common";
import { ICommonProps } from "@/types/common";
import FooterBuilder from "@/builders/footer/FooterBuilder";

const FooterConstructor = (props: ICommonProps) => {
  const { data } = props;
  if (!data.footer) return;
  const footerData = constructFooterData(data.footer, { isReact: true });

  const { form, table } = data;
  const showToggleBtn = !!(form && table);

  return (
    <FooterBuilder
      footerData={footerData}
      stickyFooter={data.stickyFooter}
      showToggleBtn={showToggleBtn}
    />
  );
};

export default FooterConstructor;
