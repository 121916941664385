// ie11 browser support
// import "react-app-polyfill/ie11";
// import "@ui5/webcomponents-base/dist/features/browsersupport/IE11";
// import "@ui5/webcomponents-react-base/polyfill/IE11";
// import "@webcomponents/webcomponentsjs/webcomponents-bundle";

// import "@ui5/webcomponents/dist/json-imports/i18n.js";

import React from "react";
import { safeStringify } from "@bsgp/lib-core";
import "@ui5/webcomponents-base/dist/features/OpenUI5Support.js";
import { ThemeProvider } from "@ui5/webcomponents-react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { Provider } from "react-redux";
import { getCurrentUser } from "actions/user";
import { Loading, ref } from "ws-process";
import * as Sentry from "@sentry/react";
import { setAutoFreeze } from "immer";
import "@ui5/webcomponents-react/dist/Assets";

import "@ui5/webcomponents-icons/dist/AllIcons.js";

setAutoFreeze(false);

store.dispatch(getCurrentUser());

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
  release: process.env.REACT_APP_RELEASE_VERSION,
  replaysOnErrorSampleRate:
    process.env.REACT_APP_API_STAGE_NAME === "prod" ? 0.2 : 0,
  environment: process.env.REACT_APP_API_STAGE_NAME,
  beforeSend(event, hint) {
    if (event.request) {
      const path = event.request.url.replace(window.location.origin, "");
      if (path.startsWith("/lc-ui5/preview/")) {
        return null;
      }
    }
    if (event.exception) {
      const firstValue = event.exception.values[0];
      if (firstValue) {
        // if (
        //   firstValue.type === "TypeError" &&
        //   firstValue.value ===
        //     "Cannot read properties of undefined (reading 'toLowerCase')"
        // ) {
        //   return null;
        // }
        if (
          firstValue.type === "Error" &&
          firstValue.value ===
            "Object captured as exception with keys: message, noMatchedMenu"
        ) {
          return null;
        }
      }
    }

    if (hint.originalException) {
      const ex = hint.originalException;
      if (ex.requestIsFalsy === true) {
        /*
          ws-process에서 request가 falsy일 경우 
          reject되는데 이것을 핸들링하여 
          sentry로 오류가 수집되는것을 방지함
        */
        return null;
      }
      if (ex.errorOccurred === true) {
        if (ex.data && ex.data.body && ex.data.body.errorCode) {
          if (ex.data.body.ignoreInSentry) {
            return null;
          }
        }
      }
    }

    hint.attachments = [
      { filename: "payload.json", data: safeStringify({ hint, event }) }
    ];
    return event;
  },

  // ignoreErrors: ["Non-Error exception captured"],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_API_STAGE_NAME === "dev" ? 0 : 0.2
});

// add event listener to the document
// to fix the backspace issue in the input fields like "";
document.addEventListener("keydown", function(event) {
  if (event.key === "Backspace") {
    event.target.value = event.target.value.replace(/[\u0008]/g, "");
    // console.log("event:", event);
  }
});

const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <Loading ref={ref} />
      <App />
    </Provider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
// });
