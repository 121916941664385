import { createSorter } from "@bsgp/lib-core";
import { functions } from "@bsgp/form-and-table";
import { viewerActions } from "./slice";

import { apiHub } from "@bsgp/lib-api";

const endPoint = "/g/lc_ui5";

export const onSortTable = ({ getProps }) => ({ sorter }) => {
  const { _dispatch, _state } = getProps();
  const items = _state.viewer.list;

  items.sort(sorter);
  _dispatch(viewerActions._updatedList({ editedList: items }));
};

export const getAllList = ({ getProps }) => () => {
  const { _dispatch } = getProps();
  try {
    apiHub.get(
      endPoint,
      { id: "*" },
      {
        afterSucceed: res => {
          const editedList = res.list;
          editedList.sort(createSorter(["updatedAt"], "desc"));
          _dispatch(viewerActions._updatedList({ editedList }));
        },
        afterFailed: err => {
          console.log("실패", err);
        }
      }
    );
  } catch (err) {
    console.log("err", err);
  }
};

export const moveToBuilder = ({ getProps }) => metaState => async oEvent => {
  const { history, path } = getProps();

  history.push(`${path}/builder/create`);
};

export const moveToBuilderWithPath = ({ getProps }) => () => oEvent => {
  const { history, path } = getProps();
  const { value } = functions.getValue(oEvent);

  history.push([`${path}/builder`, value].join("/"));
};

export const moveToRenderer = ({ getProps }) => () => oEvent => {
  const { history, path } = getProps();
  const { value } = functions.getValue(oEvent);

  history.push(`${path}${value}`);
};
