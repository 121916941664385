import React, { CSSProperties } from "react";
import {
  Form,
  FormPropTypes as UWFormPropTypes
} from "@ui5/webcomponents-react";
import { $FormSettings as OUFormPropTypes } from "sap/ui/layout/form/Form";
import { IFormObject } from "@/types/form";
import { formRef } from "@/ref";

interface IFormProps
  extends Omit<OUFormPropTypes, "layout">,
    UWFormPropTypes,
    Omit<IFormObject, "onSelect"> {
  name: string;
  key: string;
  title?: string;
  sizeV2?: boolean;
}

const defaultLayout = {
  columnsXL: 4,
  columnsL: 3,
  columnsM: 2
};

const FtForm = (props: IFormProps) => {
  const {
    className,
    containers,
    style,
    namedForm,
    sizeV2,
    tightContainers,
    compactSize,
    ...restProps
  } = props;

  return (
    <Form
      ref={formRef}
      style={{
        padding: "0.5rem 0",
        overflowX: "auto",
        ...(style as CSSProperties)
      }}
      // titleText={title}
      className={className}
      onSubmit={event => {
        event.preventDefault();
      }}
      {...defaultLayout}
      {...restProps}
    />
  );
};

export default FtForm;
